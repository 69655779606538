import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import EmailSvg from 'assets/images/Email.svg'
import EmailGoogle from 'assets/images/email_gmail.png'
import EmailOutlook from 'assets/images/email_outlook.png'
import { YogiButton } from 'src/components/UI/YogiButton'
import { Link } from 'react-router-dom'
import { signinURL } from 'utils/links'
import ArrowLeft from 'assets/images/Arrow-Left.svg'

type FormSentProps = {
  email: string
}

const FormSent: React.FC<FormSentProps> = ({ email }) => {
  return (
    <Wrapper justify="center">
      <Col>
        <Row justify="center">
          <EmailImg src={EmailSvg} />
        </Row>
        <Row justify="center">
          <Text>
            We’ve sent a reset link to <b>{email}</b>
            <br />
            It should arrive within 2 minutes.
          </Text>
        </Row>
        <Links>
          <EmailLink href="https://mail.google.com" target="_blank">
            <ImgBox>
              <EmailImgGoogle src={EmailGoogle} />
            </ImgBox>
            <LinkText>Open Gmail</LinkText>
          </EmailLink>
          <EmailLink href="https://outlook.live.com" target="_blank">
            <ImgBox>
              <EmailImgOutlook src={EmailOutlook} />
            </ImgBox>
            <LinkText>Open Outlook</LinkText>
          </EmailLink>
        </Links>
        <Links>
          <StyledLink to={signinURL}>
            <Back>
              <StyledArrowLeft src={ArrowLeft} />
              Back to Login Page
            </Back>
          </StyledLink>
        </Links>
      </Col>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  color: var(--color-auth-grey);
  font-size: 16px;
`

const EmailImg = styled.img`
  width: 64px;
  height: 44px;
  margin-bottom: 33px;
`

const Text = styled(Col)`
  max-width: 412px;
  width: 100%;
  margin-bottom: 48px;

  text-align: center;

  @media only screen and (max-width: 768px) {
    max-width: 256px;
  }
`

const Links = styled(Row)`
  justify-content: center;
  flex-flow: row nowrap;

  a:first-child {
    margin-right: 21px;
  }
`

const EmailLink = styled.a`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 148px;
  min-height: 99px;

  border: 0.5px solid #acb5bd;
  border-radius: 5px;

  color: var(--color-auth-grey);
  text-align: center;
`

const ImgBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 40px;
`

const EmailImgGoogle = styled.img`
  width: 40px;
  height: 32px;
`

const EmailImgOutlook = styled.img`
  width: 40px;
  height: 40px;
`

const LinkText = styled.div`
  margin-top: 16px;
`

const StyledLink = styled(Link)`
  margin-left: 5px;
  font-size: var(--font-size-md);
`

const Back = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin-top: 22px;
`

const StyledArrowLeft = styled.img`
  width: 20px;
  height: 16px;
  margin-right: 10px;
`

export { FormSent }
