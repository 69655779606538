import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Button, Input, Select, Tooltip } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorderGrey,
  lightText,
  mainText,
} from 'assets/styles/variables'
import mixpanel from '../../../../../trackers/mixpanel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'
import { FilterOptionSelect } from '../../../filters/types'
import { useQuery } from 'react-query'
import { Loader } from 'shared/components'
import _ from 'lodash'
import { focusFields } from '../../constants'
import { tooltip } from 'utils/tooltip-data'
import VirtuosoGridComponent from 'features/project/features/catalog/Components/CatalogList/components/VirtuosoGridComponent/VirtuosoGridComponent'
import { SearchOutlined } from '@mui/icons-material'
import YogiDivider from 'src/components/UI/YogiDivider'
import { getSourceGroups } from 'features/project/features/catalog/model'
import { getProductHierarchiesForProject } from 'features/project/features/products/model'
import { SourceList } from 'features/project/features/sourcepage/components'

export interface FocusListOption {
  value: string
  n_posts: number
  identifiers: string[]
  product_count?: number
  image_url?: string
  cluster_uuid?: string
}

export interface FocusData {
  title: string
  reviewCount: number
  identifiers: string[]
  productCount?: number
  imageUrl?: string
}

interface FocusListProps {
  selectedFocus: string | null
  setSelectedFocus: (value: string) => void
  selectedLens: string | null
  setSelectedLens: (title: string) => void
  focusListData: FocusListOption[]
  setFocusListData: (value: FocusListOption[]) => void
  tabIndex: number
  setTabIndex: (value: number) => void
}

const CatalogList: React.FC<FocusListProps> = ({
  selectedFocus,
  setSelectedFocus,
  selectedLens,
  setSelectedLens,
  focusListData,
  setFocusListData,
  tabIndex,
  setTabIndex,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const isLLMDash = !!details.clientSettings['llm-dash']

  const { Option } = Select

  const containerRef = useRef(null)

  const { isLoading, data: hierarchyData } = useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'product_hierarchy',
    },
  )

  const lensList = useMemo(
    () =>
      defaultFilterList
        ?.filter((el) => !!(el as FilterOptionSelect).values)
        ?.filter((el) => focusFields.includes(el.field))
        ?.map((el) => ({
          label: el.alias === 'Source Type' ? 'Sources' : `${el.alias}s`,
          value: el.field,
        })),
    [defaultFilterList],
  )

  const { data: sourceData, isLoading: sourcesLoading } = useQuery(
    ['sourcegroup', false, projectId],
    () => getSourceGroups('sourcegroup', false, projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'source_type',
    },
  )

  const { data: syndicatedSourceData, isLoading: syndicatedSourceLoading } =
    useQuery(
      ['syndicated_source_group', true, projectId],
      () => getSourceGroups('syndicated_source_group', true, projectId),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!projectId && selectedLens === 'source_type',
      },
    )

  const data: FocusListOption[] = useMemo(() => {
    if (selectedLens === 'product_hierarchy') {
      return (
        (
          defaultFilterList.find(
            (el) => el.field === selectedLens,
          ) as FilterOptionSelect
        )?.values ?? []
      ).map((product) => {
        const matchingHierarchy = hierarchyData?.find(
          (el) => el.hierarchy === product.value,
        )
        return {
          ...product,
          image_url: matchingHierarchy?.image_url,
          product_count: matchingHierarchy?.product_count,
        }
      })
    } else if (selectedLens === 'source_type') {
      return (
        (
          defaultFilterList.find(
            (el) => el.field === selectedLens,
          ) as FilterOptionSelect
        )?.values ?? []
      ).map((source) => {
        const matchingSource = sourceData?.find(
          (el) => el.source === source.value,
        )
        return {
          ...source,
          image_url: matchingSource?.source,
          product_count: matchingSource?.nsources,
        }
      })
    } else {
      const values =
        (
          defaultFilterList.find(
            (el) => el.field === selectedLens,
          ) as FilterOptionSelect
        )?.values ?? []
      if (selectedLens === 'theme') {
        if (isLLMDash) {
          const groupThemeValues = []
          const valueMap: any = {}
          values.forEach((el) => {
            const groupName = el.value.split(': ')[0]
            if (valueMap[groupName]) {
              valueMap[groupName].n_posts += el.n_posts
              valueMap[groupName].identifiers.push(el.cluster_uuid ?? el.value)
            } else {
              valueMap[groupName] = _.cloneDeep(el)
              valueMap[groupName].value = groupName
              valueMap[groupName].identifiers = [el.cluster_uuid ?? el.value]
            }
          })
          for (const key in valueMap) {
            if (key !== 'Critics / Detractors' && key !== 'Fans / Attractors') {
              groupThemeValues.push(valueMap[key])
            }
          }
          return groupThemeValues
        } else {
          return values.filter(
            (el) =>
              el.value !== 'Critics / Detractors' &&
              el.value !== 'Fans / Attractors',
          )
        }
      }
      return values
    }
  }, [defaultFilterList, selectedLens, hierarchyData, sourceData])

  const [sortField, setSortField] = useState<'name' | 'reviews'>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const [searchTerm, setSearchTerm] = useState('')

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const dedupedValues: any = {}

  useEffect(() => {
    if (!data) return
    let sortData = _.cloneDeep(data)
    sortData.forEach((item: FocusListOption) => {
      if (!dedupedValues[item.value]) {
        dedupedValues[item.value] = item
        dedupedValues[item.value].identifiers = item?.identifiers ?? [
          item.value,
        ]
      } else {
        dedupedValues[item.value].n_posts += item.n_posts
        if (item.product_count) {
          dedupedValues[item.value].product_count += item.product_count
        }
      }
      dedupedValues[item.value].identifiers.concat(item.identifiers)
    })

    sortData = Object.keys(dedupedValues).map((key) => ({
      value: key,
      n_posts: dedupedValues[key].n_posts,
      product_count: dedupedValues[key].product_count,
      image_url: dedupedValues[key].image_url,
      identifiers: dedupedValues[key].identifiers,
    }))

    sortData.sort((a, b) => {
      let result

      if (sortField === 'name') {
        result = b.value.localeCompare(a.value)
      } else {
        result = a.n_posts - b.n_posts
      }

      return sortOrder === 'asc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter((item) =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    }
    setFocusListData(sortData)
  }, [data, sortField, sortOrder, searchTerm])

  return (
    <Container ref={containerRef}>
      <Controls>
        <FilterControls>
          <StyledSelect
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: '100%' }}
            value={selectedLens}
            onChange={(v: any) => {
              mixpanel.track('catalog', { action: 'lens', value: v })
              setSelectedLens(v)
            }}
            placeholder="Catalog Lens"
            options={lensList}
          ></StyledSelect>
          <div style={{ height: '28px', marginTop: -1 }}>
            <YogiDivider vertical />
          </div>
          <SortControls>
            <Select
              value={sortField}
              style={{ width: 120 }}
              onChange={(value) => setSortField(value)}
            >
              <Option value="name">Name</Option>
              <Option value="reviews">Review Count</Option>
            </Select>
            <StyledButton
              onClick={() =>
                setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
              }
              style={{ height: 36 }}
            >
              {sortOrder === 'asc' ? (
                <ArrowUpOutlined />
              ) : (
                <ArrowDownOutlined />
              )}
            </StyledButton>
          </SortControls>
        </FilterControls>
        <SearchContainer>
          <StyledInput
            placeholder="Search..."
            prefix={
              <SearchOutlined style={{ color: buttonBlue, fontSize: 14 }} />
            }
            style={{
              border: '2px solid ' + cardBorderGrey,
              borderRadius: '6px',
            }}
            onChange={onSearch}
            value={searchTerm}
            suffix={<StyledCloseOutlined onClick={() => setSearchTerm('')} />}
          />
        </SearchContainer>
      </Controls>
      <Content>
        {(selectedLens === 'product_hierarchy' && isLoading) ||
        (selectedLens === 'source_type' &&
          (sourcesLoading || syndicatedSourceLoading)) ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Loader style={{ height: '50%', maxHeight: 300 }} />
            <LoaderTitle>{`Loading Options`}</LoaderTitle>
          </div>
        ) : (
          <>
            {selectedLens === 'source_type' && (
              <div style={{ marginBottom: 5 }}>
                Original Sources{' '}
                <Tooltip title={tooltip['original-sources']} placement="right">
                  <InfoCircleOutlined
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                  />
                </Tooltip>
              </div>
            )}

            <VirtuosoGridComponent
              focusListData={focusListData}
              selectedLens={selectedLens}
              setSelectedFocus={setSelectedFocus}
              selectedFocus={selectedFocus}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          </>
        )}
        {selectedLens === 'source_type' && (
          <>
            <div style={{ marginTop: 10, marginBottom: 5 }}>
              Syndicated Sources{' '}
              <Tooltip title={tooltip['syndicated-sources']} placement="right">
                <InfoCircleOutlined
                  style={{ fontSize: '16px', cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {syndicatedSourceData && (
                <SourceList
                  sources={syndicatedSourceData.filter((el) =>
                    el.source.toLowerCase().includes(searchTerm.toLowerCase()),
                  )}
                  syndicated={true}
                  projectId={projectId}
                  role={details.role}
                />
              )}
            </div>
          </>
        )}
      </Content>
    </Container>
  )
}

export default CatalogList

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
`

const Controls = styled.div`
  padding: 18px 0;
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  width: 100%;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  //width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`

const Content = styled.div`
  //padding: var(--default-padding-half) var(--default-padding);
  height: 100%;
  overflow: auto;
  width: calc(100% + 13px);
  padding-right: 13px;
  padding-bottom: 30px;
`

const StyledSelect = styled(Select)`
  height: 36px;

  .ant-select {
    height: 100%;
  }

  .ant-select-selector {
    height: 100% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

const StyledButton = styled(Button)`
  border-radius: 6px;
  border: 2px solid ${cardBorderGrey};
  color: ${mainText};
  &:hover {
    border-color: ${buttonBlue};
  }
  padding: 4px 10px;
`
const StyledInput = styled(Input)`
  height: 36px !important;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 2px !important;
  }
  .ant-input-affix-wrapper:hover {
    border-right-width: 2px !important;
  }
`
const StyledCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;

  &:hover {
    color: ${lightText};
  }
`
