import { TIMESERIES_TYPE_NAME } from '../../../../constants'
import moment from 'moment/moment'
import { OutlierType } from '../../../../model'
import React from 'react'
import useHub from 'features/project/hooks/useHub'
import { AppliedFilterOptionSelect } from 'features/project/features/filters/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import useDefaultFilterOptions from 'features/project/hooks/useDefaultFilterOptions'

type Props = {
  outlier: OutlierType
}

const QUARTER_COMPARISON_PERIOD_COUNT = 4
const DEFAULT_COMPARISON_PERIOD_COUNT = 6

const InsightSubtitle: React.FC<Props> = ({ outlier }) => {
  const { hasDatasets } = useHub()
  const { getLabelFromClusterUuid } = useDefaultFilterOptions()

  const activeDataset = (
    outlier.filter.criteria.find(
      (el) => el.field === '' + 'proj_uuid',
    ) as AppliedFilterOptionSelect
  )?.values?.[0]

  const label = getLabelFromClusterUuid('proj_uuid', activeDataset)

  const getPreviousPeriodLabel = () => {
    if (outlier.aggregation === 'quarter') {
      return `the ${QUARTER_COMPARISON_PERIOD_COUNT} ${outlier.aggregation}s prior`
    }
    return `the ${DEFAULT_COMPARISON_PERIOD_COUNT} ${outlier.aggregation}s prior`
  }
  const getMetricLabel = () => {
    if (outlier.metric === 'score') {
      return 'rating'
    }
    return outlier.metric
  }
  if (outlier.type === TIMESERIES_TYPE_NAME) {
    return (
      <div>
        In the period between{' '}
        <span style={{ fontWeight: 500 }}>
          {outlier.date ? new Date(outlier.date).toDateString() : ''} -{' '}
          {moment(outlier.date)
            // @ts-ignore
            .add(1, outlier.aggregation)
            .toDate()
            .toDateString()}
        </span>
        , the{' '}
        <i>
          <u>
            average {getMetricLabel()} for the selected data was{' '}
            {outlier.metric_score.toFixed(2)}
          </u>
        </i>
        , while the{' '}
        <i>
          <u>
            average {getMetricLabel()}
            {hasDatasets ? ` in ${label} for ` : ' in '}
            {getPreviousPeriodLabel()} was{' '}
            {outlier.mean_metric_score.toFixed(2)}
          </u>
        </i>
        .
      </div>
    )
  } else {
    return (
      <div>
        In the theme of <span style={{ fontWeight: 500 }}>{outlier.theme}</span>
        , {outlier.context_with_value?.value} has an{' '}
        <i>
          <u>
            average {getMetricLabel()} of {outlier.metric_score.toFixed(2)}
          </u>
        </i>
        , while the{' '}
        <i>
          <u>
            average {getMetricLabel()} of all{' '}
            {outlier.context_with_value?.context}s
            {hasDatasets ? ` in ${label} ` : ' '}
            is {outlier.mean_metric_score.toFixed(2)}
          </u>
        </i>
        .
      </div>
    )
  }
}

export default InsightSubtitle
