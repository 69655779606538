import {
  Button,
  Checkbox,
  InputNumber,
  Popover,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd'
import mixpanel from '../../../../../trackers/mixpanel'
import { InfoCircleOutlined } from '@ant-design/icons'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  buttonBlue,
  buttonHover,
  cardBorderGrey,
} from 'assets/styles/variables'
import { ChartTypes } from '../../utils'
import {
  DashboardChartResponse,
  LineChart,
  StackBarChart,
  TimeInterval,
} from '../../types'
import { IntervalSelector } from '../interval-selector'
import styled from 'styled-components'
import { YogiButton } from 'components/UI/YogiButton'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'
import { useQuery } from 'react-query'
import { getReviewsCount } from '../../model'
import { AuthContext } from 'features/auth'
import {
  fontSizeOptions,
  postTypeOptions,
} from 'features/project/features/Charts/constants'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import SettingsSvgIcon from 'features/project/features/Charts/components/ChartSettings/components/SettingsSvgIcon/SettingsSvgIcon'

type Props = {
  chartItem: DashboardChartResponse
  dashboardControls: any
  updateDashboardControls: (field: string, value: any) => void
  expandedCategories?: string[]
}
export const ChartSettings: React.FC<Props> = ({
  chartItem,
  dashboardControls,
  updateDashboardControls,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const [data, setData] = useState(chartItem?.charts[0])
  const {
    tier1,
    legend,
    fullAxis,
    hasLabels,
    treeView,
    origin,
    trendlines,
    shadedZones,
    sortedByCategory,
    multiChart,
    postType,
    reviewsCount,
    fontSize,
    showPercentChange,
    wrapLabels,
    maxAxesLength,
    matrixScaleVolume,
    matrixSort,
    matrixFlipAxis,
    matrixSortAxis,
    matrixSortValue,
    matrixSpectrumColors,
    hideParentCategories,
    hideXAxis,
    hideYAxis,
  } = dashboardControls ?? {}

  const authContext = useContext(AuthContext)
  const isYogi = authContext.user?.attributes?.email.includes('@meetyogi.com')

  const { data: reviewsCountData } = useQuery(
    ['reviews-count'],
    () => getReviewsCount({ proj_uuid: projectId }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )
  const maxReviewsCount = reviewsCountData?.reviews

  const isReviewsControlVisible = useMemo(() => {
    if (chartItem?.tier1) {
      const currentIndex = chartItem.tier1.options.findIndex(
        (item: any) => item.name === tier1,
      )
      const divideIndex = chartItem.tier1.options.find(
        (item: any) => item.name === 'bar',
      )?.value[0]
      return divideIndex ? currentIndex > divideIndex : true
    }
  }, [tier1, chartItem])

  const showMultiChartCheckbox = useMemo(() => !!(data as any)?.z_key, [data])

  const [localReviewsCount, setLocalReviewsCount] = useState<
    number | undefined
  >(reviewsCount)

  const [localAxesLength, setLocalAxesLength] = useState<number | undefined>(
    maxAxesLength,
  )

  useEffect(() => {
    setLocalReviewsCount(reviewsCount)
  }, [reviewsCount])

  const saveReviewsCount = () => {
    mixpanel.track('chart config', {
      action: 'count thresh',
      ...route,
      value: localReviewsCount,
    })
    if (localReviewsCount !== undefined)
      updateDashboardControls('reviewsCount', localReviewsCount)
  }

  const saveMaxAxesLength = () => {
    mixpanel.track('chart config', {
      action: 'axes length',
      ...route,
      value: localAxesLength,
    })
    if (localAxesLength !== undefined)
      updateDashboardControls('maxAxesLength', localAxesLength)
  }

  const clearReviewsCount = () => {
    if (reviewsCount !== undefined) updateDashboardControls('reviewsCount', 0)
    setLocalReviewsCount(0)
  }

  const clearMaxAxes = () => {
    if (reviewsCount !== undefined) updateDashboardControls('maxAxesLength', 30)
    setLocalAxesLength(30)
  }

  useEffect(() => {
    setData(chartItem?.charts[0])
  }, [chartItem])

  const content = (
    <Content>
      <Radio.Group
        disabled={!chartItem?.enable_post_type}
        options={postTypeOptions}
        value={chartItem?.enable_post_type ? postType : undefined}
        onChange={(event) => {
          mixpanel.track('chart config', {
            action: 'post type',
            ...route,
            value: event.target.value,
          })
          updateDashboardControls('postType', event.target.value)
        }}
      />
      <Select
        style={{ marginTop: 10 }}
        value={fontSize}
        onChange={(v) => {
          mixpanel.track('chart config', {
            action: 'font size',
            value: v,
            ...route,
          })
          updateDashboardControls('fontSize', v)
        }}
        placeholder="Font size"
      >
        {fontSizeOptions.map((option: any) => (
          <Select.Option
            value={option.value}
            key={option.value}
            selected={option.value === fontSize}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      {isReviewsControlVisible && (
        <ReviewsControl>
          <ReviewsControlTitle>Minimum R&R</ReviewsControlTitle>
          {maxReviewsCount || maxReviewsCount === 0 ? (
            <>
              <Space>
                <InputNumber
                  style={{
                    width: 120,
                    borderRadius: 4,
                    paddingTop: 3,
                    paddingBottom: 3,
                    border: '2px solid' + cardBorderGrey,
                    fontSize: 10,
                  }}
                  min={0}
                  max={maxReviewsCount}
                  value={localReviewsCount}
                  onChange={(val) => {
                    setLocalReviewsCount(val || 0)
                  }}
                />
                <ReviewsButton
                  type="text"
                  disabled={localReviewsCount === 0}
                  onClick={clearReviewsCount}
                >
                  Clear
                </ReviewsButton>
                <ReviewsButton
                  type="text"
                  disabled={localReviewsCount === reviewsCount}
                  onClick={saveReviewsCount}
                >
                  Apply
                </ReviewsButton>
              </Space>
            </>
          ) : (
            <Spin />
          )}
        </ReviewsControl>
      )}
      {ChartTypes.NUMERICAL !== data?.chart_type &&
        ChartTypes.TABLE !== data?.chart_type && (
          <>
            {isYogi && (
              <>
                <Checkbox
                  checked={hideXAxis}
                  onChange={(event) => {
                    mixpanel.track('chart config', {
                      action: 'hideXAxis',
                      value: event.target.checked,
                      ...route,
                    })
                    updateDashboardControls('hideXAxis', event.target.checked)
                  }}
                >
                  Hide X Axis Label
                </Checkbox>

                <Checkbox
                  checked={hideYAxis}
                  onChange={(event) => {
                    mixpanel.track('chart config', {
                      action: 'hideYAxis',
                      value: event.target.checked,
                      ...route,
                    })
                    updateDashboardControls('hideYAxis', event.target.checked)
                  }}
                >
                  Hide Y Axis Label
                </Checkbox>
              </>
            )}
            <Checkbox
              style={{ marginTop: 5 }}
              checked={fullAxis}
              onChange={(event) => {
                mixpanel.track('chart config', {
                  action: 'full axes',
                  value: event.target.checked,
                  ...route,
                })
                updateDashboardControls('fullAxis', event.target.checked)
              }}
            >
              Show Full Axis Titles
            </Checkbox>
            <Checkbox
              checked={hasLabels}
              onChange={(event) => {
                mixpanel.track('chart config', {
                  action: 'labels',
                  value: event.target.checked,
                  ...route,
                })
                updateDashboardControls('hasLabels', event.target.checked)
              }}
            >
              Show Labels
            </Checkbox>
            <Checkbox
              checked={legend}
              onChange={(event) => {
                mixpanel.track('chart config', {
                  action: 'legend',
                  value: event.target.checked,
                  ...route,
                })
                updateDashboardControls('legend', event.target.checked)
              }}
            >
              Show Legend
            </Checkbox>
          </>
        )}
      {ChartTypes.BUBBLE === data?.chart_type && 'origin' in data && (
        <Checkbox
          checked={origin}
          onChange={(event) => {
            mixpanel.track('chart config', {
              action: 'origin',
              value: event.target.checked,
              ...route,
            })
            updateDashboardControls('origin', event.target.checked)
          }}
          disabled={!(data as any).origin}
        >
          Show Origin
        </Checkbox>
      )}
      {ChartTypes.PIE === data?.chart_type && (
        <Checkbox
          checked={treeView}
          onChange={(event) => {
            mixpanel.track('chart config', {
              action: 'treemap',
              value: event.target.checked,
              ...route,
            })
            updateDashboardControls('treeView', event.target.checked)
          }}
        >
          Show Tree Map
        </Checkbox>
      )}
      {((ChartTypes.BUBBLE === data?.chart_type && 'lobf' in data) ||
        (ChartTypes.LINE === data?.chart_type && 'lobf' in data)) && (
        <Checkbox
          checked={trendlines}
          onChange={(event) => {
            mixpanel.track('chart config', {
              action: 'trendlines',
              value: event.target.checked,
              ...route,
            })
            updateDashboardControls('trendlines', event.target.checked)
          }}
          disabled={!(data as any).lobf}
        >
          Show Trendlines
        </Checkbox>
      )}
      {((ChartTypes.BUBBLE === data?.chart_type && 'shaded_zones' in data) ||
        (ChartTypes.LINE === data?.chart_type && 'shaded_zones' in data)) && (
        <Checkbox
          checked={shadedZones}
          onChange={(event) => {
            mixpanel.track('chart config', {
              action: 'zones',
              value: event.target.checked,
              ...route,
            })
            updateDashboardControls('shadedZones', event.target.checked)
          }}
          disabled={!(data as any).shaded_zones}
        >
          Show Shaded Zones
        </Checkbox>
      )}
      {ChartTypes.LINE === data?.chart_type && showMultiChartCheckbox && (
        <Checkbox
          checked={multiChart}
          onChange={(event) => {
            mixpanel.track('chart config', {
              action: 'volume',
              value: event.target.checked,
              ...route,
            })
            updateDashboardControls('multiChart', event.target.checked)
          }}
        >
          Show Volume
        </Checkbox>
      )}
      {(ChartTypes.STACK_BAR === data?.chart_type ||
        ChartTypes.BAR === data?.chart_type) && (
        <>
          <Checkbox
            checked={sortedByCategory}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'sort',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('sortedByCategory', event.target.checked)
            }}
          >
            Sort By Category
          </Checkbox>
        </>
      )}
      {(data?.chart_type === ChartTypes.STACK_BAR ||
        data?.chart_type === ChartTypes.BAR ||
        data?.chart_type === ChartTypes.MATRIX ||
        data?.chart_type === ChartTypes.PIE) && (
        <>
          <Checkbox
            checked={wrapLabels}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'wrapLabels',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('wrapLabels', event.target.checked)
            }}
          >
            Wrap Labels
          </Checkbox>

          {wrapLabels && (
            <>
              <ReviewsControlTitle style={{ marginTop: 5, marginBottom: 0 }}>
                Max Label Length
              </ReviewsControlTitle>
              <Space>
                <InputNumber
                  style={{
                    width: 120,
                    borderRadius: 4,
                    paddingTop: 3,
                    paddingBottom: 3,
                    border: '2px solid' + cardBorderGrey,
                    fontSize: 10,
                  }}
                  min={0}
                  value={localAxesLength}
                  onChange={(val) => {
                    // @ts-ignore
                    setLocalAxesLength(val)
                  }}
                />
                <ReviewsButton
                  type="text"
                  disabled={localAxesLength === 30}
                  onClick={clearMaxAxes}
                >
                  Clear
                </ReviewsButton>
                <ReviewsButton
                  type="text"
                  disabled={localAxesLength === maxAxesLength}
                  onClick={saveMaxAxesLength}
                >
                  Apply
                </ReviewsButton>
              </Space>
            </>
          )}
        </>
      )}

      {data?.chart_type === ChartTypes.TABLE && (
        <>
          <Checkbox
            checked={showPercentChange}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'showPercentChange',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('showPercentChange', event.target.checked)
            }}
          >
            Show Percent Change
          </Checkbox>
          <Checkbox
            checked={hideParentCategories}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'hideParentCategories',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls(
                'hideParentCategories',
                event.target.checked,
              )
            }}
          >
            Hide Parent Rows
          </Checkbox>
        </>
      )}
      {ChartTypes.LINE === data?.chart_type && (data as LineChart).interval && (
        <IntervalSelector
          value={(data as LineChart).interval}
          onChange={async (value) => {
            mixpanel.track('chart config', {
              action: 'time interval',
              value: value as TimeInterval,
              ...route,
            })
            updateDashboardControls('timeInterval', value)
          }}
        />
      )}
      {ChartTypes.STACK_BAR === data?.chart_type &&
        (data as LineChart).interval && (
          <IntervalSelector
            value={(data as LineChart).interval}
            onChange={async (value) => {
              mixpanel.track('chart config', {
                action: 'time interval',
                value: value as TimeInterval,
                ...route,
              })
              updateDashboardControls('timeInterval', value)
            }}
          />
        )}
      {data?.chart_type === ChartTypes.MATRIX && (
        <>
          <Checkbox
            checked={matrixSpectrumColors}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'matrixSpectrumColors',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls(
                'matrixSpectrumColors',
                event.target.checked,
              )
            }}
          >
            Show Color Spectrum
          </Checkbox>
          <Checkbox
            checked={matrixScaleVolume}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'matrixScaleVolume',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('matrixScaleVolume', event.target.checked)
            }}
          >
            Scale Volume
            <Tooltip
              title={
                'Scale the height of the chart cells to the proportion of conversation within the Breakdown field.'
              }
            >
              {' '}
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
          <Checkbox
            checked={matrixFlipAxis}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'matrixFlipAxis',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('matrixFlipAxis', event.target.checked)
            }}
          >
            Flip Matrix Axis
          </Checkbox>
          <Checkbox
            checked={matrixSort}
            onChange={(event) => {
              mixpanel.track('chart config', {
                action: 'matrixSort',
                value: event.target.checked,
                ...route,
              })
              updateDashboardControls('matrixSort', event.target.checked)
            }}
          >
            Sort Matrix
          </Checkbox>
          <div style={{ marginTop: 10, width: '100%' }}>
            <ReviewsControlTitle>Sort Axis</ReviewsControlTitle>
            <Select
              style={{ width: '100%' }}
              value={matrixSortAxis}
              onChange={(v) => {
                mixpanel.track('chart config', {
                  action: 'matrixSortAxis',
                  value: v,
                  ...route,
                })
                updateDashboardControls('matrixSortAxis', v)
                updateDashboardControls('matrixSortValue', undefined)
              }}
              placeholder="Sort Axis"
              disabled={!matrixSort && (chartItem.charts[0] as any).x_title}
              options={(chartItem.charts[0] as any).x_title
                ?.split('-')
                ?.reverse()
                ?.map((item: string, index: number) => ({
                  label: item,
                  value: index === 0 ? 'y' : 'x',
                }))}
            />
          </div>
          <div style={{ marginTop: 10, width: '100%' }}>
            <ReviewsControlTitle>Sort Value</ReviewsControlTitle>
            <Select
              style={{ width: '100%' }}
              value={matrixSortValue}
              onChange={(v) => {
                mixpanel.track('chart config', {
                  action: 'matrixSortValue',
                  value: v,
                  ...route,
                })
                updateDashboardControls('matrixSortValue', v)
              }}
              placeholder="Sort Value"
              disabled={
                !matrixSort &&
                (chartItem.charts[0] as any).x_title &&
                matrixSortAxis
              }
              options={[
                ...new Set(
                  Object.keys(
                    (chartItem.charts[0] as StackBarChart).category_mapping ??
                      {},
                  )?.map((item: any) =>
                    matrixSortAxis === 'x'
                      ? item.split(' - ')[0]
                      : item.split(' - ')[1],
                  ),
                ),
              ].map((item: string) => ({ label: item, value: item }))}
            />
          </div>
        </>
      )}
    </Content>
  )

  return (
    <Popover
      content={content}
      title={<div style={{ padding: '5px', fontSize: 12 }}>Settings</div>}
      trigger="click"
      placement="topLeft"
    >
      {/*<Badge*/}
      {/*  dot={!!reviewsCount}*/}
      {/*  style={{ zIndex: 1, display: 'flex', alignItems: 'center' }}*/}
      {/*>*/}
      {/*<SettingFilled*/}
      {/*  style={{ width: 16, height: 16, margin: '0 auto', color: buttonBlue }}*/}
      {/*  */}
      {/*/>*/}

      <IconWrapper
        onClick={() => mixpanel.track('chart settings', { ...route })}
      >
        <GenericSvgIcon SvgComponent={SettingsSvgIcon} />
      </IconWrapper>
      {/*</Badge>*/}
    </Popover>
  )
}
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 5px;

  .ant-select {
    .ant-select-selector {
      height: 40px;
      padding: 5px 10px;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`

const SettingsButton = styled(YogiButton)`
  z-index: 10;
  //border: none;
  background: white;
  box-shadow: none;
  padding-left: 7px;
  //padding-right: 10px;
  border: 2px solid var(--card-border-grey);
  border-radius: 4px;

  border svg {
    height: 16px;
    width: 16px;
  }
`

const ReviewsControl = styled.div`
  padding: 10px 0px;
`

const ReviewsControlTitle = styled.div`
  margin-bottom: 6px;
  font-size: var(--font-size-md);
`

const ReviewsButton = styled(Button)`
  padding: 0;
`

const IconWrapper = styled.div`
  color: ${buttonBlue};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${buttonHover};
  }
`
