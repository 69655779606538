import { CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import {
  buttonBlue,
  darkBlueFontColor,
  mainText,
} from 'assets/styles/variables'
import React, { useMemo } from 'react'
import mixpanel from 'features/trackers/mixpanel'
import { Select } from 'antd'
import _ from 'lodash'

type Props = {
  options: { label: string; value: string }[]
  values: string[]
  setValues: React.Dispatch<React.SetStateAction<string[]>>
  type: string
}
export const CustomSelect: React.FC<Props> = ({
  options,
  values,
  setValues,
  type,
}) => {
  const controlChangeMixpanelEvent = (value: string[]) => {
    mixpanel.track('insights feed', {
      action: 'filter',
      type,
      value,
    })
  }

  const [search, setSearch] = React.useState('')

  const filteredOptions = useMemo(
    () =>
      [...(options ?? [])]?.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      ) ?? [],
    [options, search],
  )

  const caseType = _.startCase(type)

  return (
    <StyledSelect
      mode={'multiple'}
      allowClear
      style={{ width: 200 }}
      placeholder={`Selected ${caseType}s`}
      defaultValue={[]}
      onChange={(e) => {
        setValues(e as string[])
        controlChangeMixpanelEvent(e as string[])
      }}
      onSearch={(value) => {
        setSearch(value)
      }}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={options}
      maxTagCount={1}
      popupMatchSelectWidth={250}
      value={values}
      onKeyDown={(e) => {
        // this indicates an item was successfully selected by the enter press,
        // so we want to clear the search input so the filtered list resets
        if (e.key === 'Enter' && filteredOptions.length > 0) {
          setSearch('')
        }
      }}
      tagRender={(tag) => {
        if (!tag.isMaxTag) {
          return (
            <CustomTagContainer>
              <CustomTag>
                {values.length > 1
                  ? `${values.length} ${caseType}s`
                  : tag.label}
              </CustomTag>
            </CustomTagContainer>
          )
        }
        return <div></div>
      }}
      dropdownRender={(menu) => {
        return (
          <CustomDropdown>
            <CustomOptions
              style={{
                marginBottom:
                  !values?.length || values?.length === options?.length
                    ? 0
                    : 14,
              }}
            >
              {values?.sort().map((value, index) => (
                <SelectedDropdownOption
                  onClick={() => {
                    setValues((prev) => [...prev].filter((el) => el !== value))
                  }}
                >
                  <div>{options.find((el) => el.value === value)?.label}</div>
                  <CloseSelectedItem>
                    <CloseOutlined />
                  </CloseSelectedItem>
                </SelectedDropdownOption>
              ))}
            </CustomOptions>
            <CustomOptions style={{ gap: 0 }}>
              {filteredOptions
                ?.filter((el) => !values?.includes(el.value))
                .map((el) => (
                  <UnselectedDropdownOption
                    onClick={() => {
                      setValues((prev) => [...prev, el.value])
                    }}
                  >
                    {el.label}
                  </UnselectedDropdownOption>
                ))}
            </CustomOptions>
            <Hidden>{menu}</Hidden>
          </CustomDropdown>
        )
      }}
    />
  )
}

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow {
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-select-selection-overflow-item {
    display: flex;
    span {
      max-width: 100%;
    }
  }
  margin-right: 5px;

  .ant-select-selector {
    border: 2px solid #d8d8d8 !important;
    box-shadow: 0px 0px 5px 0px #00000026;
    border-radius: 10px !important;
  }

  .ant-select-selection-search {
    padding-left: 8px !important;
    margin: 0;
  }

  .ant-select-selection-placeholder {
    color: ${mainText};
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 0;
  }

  .ant-select-selection-search-input {
    font-size: 12px !important;
    width: fit-content !important;
    margin: 0px 2px 0 0 !important;
    min-width: auto !important;
  }
`
const SelectedDropdownOption = styled.div`
  background: #dce3ed;
  border-radius: 10px;
  color: ${buttonBlue};
  padding: 4px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UnselectedDropdownOption = styled.div`
  padding: 4px 12px;
  border-radius: 10px;
  cursor: pointer;

  color: ${darkBlueFontColor};

  transition: all 0.2s ease-in-out;
  &:hover {
    background: #dce3ed;
  }
`

const CustomDropdown = styled.div`
  margin-right: 12px;
  padding: 12px 12px 12px 12px;

  max-height: 300px;
  overflow: auto;
`

const CustomOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

const CloseSelectedItem = styled.div`
  cursor: pointer;
`

const CustomTagContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
`

const CustomTag = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Hidden = styled.div`
  opacity: 0;
  max-height: 0;
  overflow: hidden;
`
