import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Amplify } from 'aws-amplify'
import {
  SignInForm,
  ResetPasswordForm,
  NewPasswordForm,
  AuthContainer,
  SetupPasswordForm,
  VerifyForm,
  SignInCallback,
} from 'features/auth'
import { ProfileContainer } from 'features/profile'
import { Project, SharePage } from 'features/project'
import { HomeContainer } from 'features/home'
import {
  home,
  signinURL,
  verifyURL,
  profileURL,
  projectURL,
  newPasswordURL,
  shareProjectURL,
  resetPasswordURL,
  setupPasswordURL,
  signinCallbackURL,
  trialSignupURL,
  trialURL,
  requestAccessURL,
  shareCustomDashboardURL,
} from 'utils/links'
import GA from 'utils/GoogleAnalytics'
import { runTrackers } from './trackers'
import awsConfig from './aws-exports'
import { SignUpTrialForm } from 'features/auth/forms/signup_trial_form'
import { TrialContainer } from 'features/trial/containers/trial-page-container'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { env } from 'utils/axios'
import RequestAccessPage from 'features/auth/components/RequestAccessPage'
import { DashboardSharePage } from 'features/project/features/shared-dashboard'
import { ConfigProvider } from 'antd'

import 'normalize.css/normalize.css'
import 'antd/dist/reset.css'
import 'rc-color-picker/assets/index.css'
import 'react-toastify/dist/ReactToastify.css'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import 'assets/styles/base.css'
import 'assets/styles/variables.css'

// If these are needed globally, keep them here
import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min'
import 'chart-js-treemap/src/index'

const queryClient = new QueryClient()

let cognitoDomain: string
let redirectSignIn: string
let redirectSignOut: string

if (env === 'prod') {
  cognitoDomain = 'meetyogi-prod.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app.meetyogi.com/sign-in'
} else if (env === 'staging') {
  cognitoDomain = 'meetyogi-staging.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-staging.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-staging.meetyogi.com/sign-in'
} else if (env === 'dev') {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-dev.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-dev.meetyogi.com/sign-in'
} else if (env === 'sandbox') {
  cognitoDomain = 'meetyogi-sandbox.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'https://app-sandbox.meetyogi.com/sign-in/callback'
  redirectSignOut = 'https://app-sandbox.meetyogi.com/sign-in'
} else if (env === 'local') {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'http://localhost:3000/sign-in/callback'
  redirectSignOut = 'http://localhost:3000/sign-in'
} else {
  cognitoDomain = 'meetyogi-dev.auth.us-east-1.amazoncognito.com'
  redirectSignIn = 'http://localhost:3000/sign-in/callback'
  redirectSignOut = 'http://localhost:3000/sign-in'
}

export const App = () => {
  // Configure Amplify with the new v6 structure
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: awsConfig.aws_user_pools_id,
        userPoolClientId: awsConfig.aws_user_pools_web_client_id,
        identityPoolId: awsConfig.aws_cognito_identity_pool_id,
        loginWith: {
          oauth: {
            providers: [{ custom: 'Okta' }],
            domain: cognitoDomain,
            scopes: [
              // 'phone',
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: [redirectSignIn],
            redirectSignOut: [redirectSignOut],
            responseType: 'code',
          },
        },
      },
    },
  })

  const [mixpanelLoaded, setMixpanelLoaded] = useState(false)

  useEffect(() => {
    runTrackers()
    setMixpanelLoaded(true)
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins, sans-serif',
          lineHeight: 1.5715,
          colorPrimary: '#4070c0',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            {GA.init() && <GA.RouteTracker />}
            {mixpanelLoaded && (
              <Switch>
                <Route path={shareProjectURL} component={SharePage} />
                <Route
                  path={shareCustomDashboardURL}
                  component={DashboardSharePage}
                />
                <AuthContainer>
                  <Switch>
                    <Route path={home} component={HomeContainer} exact />
                    <Route path={signinURL} component={SignInForm} exact />
                    <Route
                      path={trialSignupURL}
                      component={SignUpTrialForm}
                      exact
                    />
                    <Route
                      path={signinCallbackURL}
                      component={SignInCallback}
                      exact
                    />
                    <Route path={verifyURL} component={VerifyForm} exact />
                    <Route
                      path={resetPasswordURL}
                      component={ResetPasswordForm}
                      exact
                    />
                    <Route
                      path={newPasswordURL}
                      component={NewPasswordForm}
                      exact
                    />
                    <Route
                      path={setupPasswordURL}
                      component={SetupPasswordForm}
                      exact
                    />
                    <Route
                      path={profileURL}
                      component={ProfileContainer}
                      exact
                    />
                    <Route path={projectURL()} component={Project} />
                    <Route path={trialURL} component={TrialContainer} />
                    <Route
                      path={requestAccessURL}
                      component={RequestAccessPage}
                    />
                  </Switch>
                </AuthContainer>
              </Switch>
            )}
          </BrowserRouter>
          <ToastContainer hideProgressBar={true} position="bottom-right" />
          {/*<ReactQueryDevtools initialIsOpen={true} />*/}
        </DndProvider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
