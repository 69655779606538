import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import { Typography, Form, Input, Button } from 'antd'
import { AuthWrapper } from 'shared/wrappers'
import { Loader } from 'shared/components'
import mixpanel from 'features/trackers/mixpanel'
import { home } from 'utils/links'
import { setUpPassword, signIn, updateLastVisit } from '../model'
import { useAuthContext } from 'features/auth'
import { fetchUserAttributes, getCurrentUser } from '@aws-amplify/auth'

const layout = {
  labelCol: { span: 7 },
}

const SetupPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Confirm Password is required'),
})

export const SetupPasswordForm = () => {
  const authContext = useAuthContext()
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [signInError, setSignInError] = useState(false)
  const [cognitoUser, setCognitoUser] = useState(null)
  const formik = useFormik({
    initialValues: { code: '', password: '', passwordConfirmation: '' },
    validationSchema: SetupPasswordSchema,
    onSubmit: async ({ password }) => {
      try {
        if (!cognitoUser) return
        setLoading(true)
        await setUpPassword(cognitoUser, password)
        const email = JSON.parse(atob(queryParams.data)).email

        const user = await getCurrentUser()

        if (email) {
          mixpanel.alias(email)
        }

        authContext.signIn(user)
        toast.info('Success')
        history.push(home)
      } catch (e) {
        setLoading(false)
        toast.error(e.message)
      }
    },
  })
  const passwordError = formik.touched.password && formik.errors.password
  const passwordConfirmationError =
    formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
  const queryParams = queryString.parse(location.search)

  useEffect(() => {
    if (queryParams.data && queryParams.code) {
      const data = JSON.parse(atob(queryParams.data))

      signIn(data.email, queryParams.code)
        .then(setCognitoUser)
        .catch(() => setSignInError(true))
    }
  }, [])

  return (
    <AuthWrapper>
      <Wrapper onFinish={formik.submitForm} {...layout}>
        <FormTitle>
          <Typography.Title level={4}>Create your password</Typography.Title>
          <Typography.Text>
            Please enter a password that is at least 8 characters long and
            contains at least 1 lowercase character, 1 uppercase character, 1
            special character, and 1 number.
          </Typography.Text>
        </FormTitle>
        {signInError && (
          <Error>
            Invalid credentials or expired password. Please contact{' '}
            <a href="mailto:support@meetyogi.com">support@meetyogi.com</a> for
            assistance.
          </Error>
        )}
        <Form.Item
          label="New password"
          validateStatus={passwordError && 'error'}
          help={passwordError}
        >
          <Input.Password
            name="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
            disabled={!cognitoUser}
          />
        </Form.Item>
        <Form.Item
          label="Confirmation"
          validateStatus={passwordConfirmationError && 'error'}
          help={passwordConfirmationError}
        >
          <Input.Password
            name="passwordConfirmation"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.passwordConfirmation}
            disabled={!cognitoUser}
          />
        </Form.Item>
        {loading && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <SubmitButton
            type="primary"
            htmlType="submit"
            disabled={!cognitoUser}
          >
            Submit
          </SubmitButton>
        </Form.Item>
      </Wrapper>
    </AuthWrapper>
  )
}

const Wrapper = styled(Form)`
  max-width: 500px;
  margin: 0 auto;
  box-shadow: var(--default-box-shadow);
  padding: var(--default-padding);
  position: relative;
  background: #fff;

  @media (max-width: 768px) {
    max-width: none;
    margin: var(--mobile-margin);
  }
`
const FormTitle = styled.div`
  text-align: center;
  margin-bottom: var(--default-padding);
`
const SubmitButton = styled(Button)`
  margin-top: var(--default-padding);
  display: block;
  margin-left: auto;
`
const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
const Error = styled.div`
  color: #ff4d4f;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`
