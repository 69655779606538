import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import InsightList from './components/InsightList/InsightList'
import { useQueries, useQuery } from 'react-query'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { Cohort, getOutlierAnalysis, getOutliers, OutlierType } from './model'
import InsightDetail from './components/InsightDetail/InsightDetail'
import { constructOutliersList } from './utils'
import useCustomDashboards from '../../hooks/useCustomDashboards'
import { DEFAULT_MODEL } from './constants'
import mixpanel from '../../../trackers/mixpanel'
import { Bookmarks } from 'features/project/features/Insights/components/Bookmarks/Bookmarks'
import {
  Controls,
  StartButton,
} from 'features/project/features/Insights/components/Controls/Controls'
import {
  BackSVG,
  CoolDividerSVG,
  LandingPageSVG,
} from 'features/project/features/Insights/components/InsightsFeedSVGComponents/InsightsFeedSVGComponents'
import styled from 'styled-components'
import {
  buttonBlue,
  darkBlueFontColor,
  lightBlueFontColor,
  pageBackgroundGrey,
  primaryOrange,
} from 'assets/styles/variables'
import { emptyFilters, getFromLS } from 'features/project/utils'
import { useFilterList } from 'features/project/hooks/useFilterList'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { useHistory } from 'react-router'
import { AuthContext } from 'features/auth'
import PageDurationTracker from 'components/PageDurationTracker/PageDurationTracker'
import spotGif from 'assets/gifs/spotsniff.gif'
import { Bookmark } from '@mui/icons-material'
import postsEmpty from 'assets/images/empty/PostsEmpty.png'

const emptyCohort = {
  brands: [],
  products: [],
  datasets: [],
  themes: [],
}

const emptyAdditionalFilters = {
  type: '',
  context: '',
  direction: '',
  period: '',
}

const Insights: React.FC = () => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [filterValues, setFilterValues] = useState({
    ...initialState.filters[0],
    // ...emptyFilters,
  })
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(1)
  const [hiddenPage, setHiddenPage] = useState(1)
  const [search, setSearch] = React.useState('')
  const [cohort, setCohort] = useState<Cohort | undefined>()
  const [additionalFilters, setAdditionalFilters] = useState(
    emptyAdditionalFilters,
  )

  useEffect(() => {
    prevResultData.current = []
  }, [cohort, additionalFilters])

  const notInterestedList = getFromLS(`${projectId}_notInterested`)

  const history = useHistory()
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email

  // api
  const { data: outlierResponse } = useQuery(
    ['outliers', projectId, filterValues],
    () => getOutliers(projectId, filterValues),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    },
  )

  const brandFilterValues = {
    ...emptyFilters,
    values: [
      {
        field: 'brand',
        values: cohort?.brands ?? [],
      },
    ],
  }

  const { data: filterListData, isLoading: filterListLoading } = useFilterList(
    cohort?.brands.length ? brandFilterValues : emptyFilters,
    cohort?.brands.length ? ['product_hierarchy'] : [],
  )

  // "saved" insights are stored in the custom dashboards
  const { data: customDashboardsResponse } = useCustomDashboards()

  const minVolume = 10
  const pageSize = 6
  // derived constants
  const allItems = useMemo(
    () =>
      constructOutliersList(
        outlierResponse,
        customDashboardsResponse,
        currentUserEmail,
        activeTab,
        minVolume,
        pageSize,
        hiddenPage,
        search,
        cohort,
        filterListData,
        [],
        emptyAdditionalFilters,
      ),
    [
      outlierResponse,
      activeTab,
      minVolume,
      pageSize,
      hiddenPage,
      search,
      cohort,
      filterListData,
    ],
  )
  const activeItems = useMemo(
    () =>
      constructOutliersList(
        outlierResponse,
        customDashboardsResponse,
        currentUserEmail,
        activeTab,
        minVolume,
        pageSize,
        hiddenPage,
        search,
        cohort,
        filterListData,
        notInterestedList,
        additionalFilters,
        true,
      ),
    [
      outlierResponse,
      activeTab,
      minVolume,
      pageSize,
      hiddenPage,
      search,
      cohort,
      filterListData,
      additionalFilters,
    ],
  )

  const urlParams = new URLSearchParams(window.location.search)

  const activeModel = DEFAULT_MODEL //  model ?? DEFAULT_MODEL
  const results = useQueries(
    activeItems.slice(0, page * pageSize).map((outlier) => {
      // const prompt = constructThemeOutlierPrompt(outlier)

      // delete outlier.filter.criteria.find(
      //   (filter) => filter.field === 'create_time',
      // )?.values
      // delete outlier.filter.criteria.find((filter) => filter.field === 'score')
      //   ?.values
      return {
        queryKey: ['analysis', projectId, activeModel, outlier.uuid], //, prompt],
        queryFn: () =>
          getOutlierAnalysis(
            projectId,
            outlier,
            outlier.filter,
            activeModel,
            //prompt
          ),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!projectId && !!outlier,
        retry: 3,
      }
    }),
  )

  const backgroundResults = useQueries(
    activeItems.slice(page * pageSize, (page + 1) * pageSize).map((outlier) => {
      return {
        queryKey: ['analysis', projectId, activeModel, outlier.uuid],
        queryFn: () =>
          getOutlierAnalysis(
            projectId,
            outlier,
            outlier.filter,
            activeModel,
            //prompt
          ),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!projectId && !!outlier,
        retry: 3,
      }
    }),
  )

  const analysesAreLoading = results.some((result) => result.isLoading)
  const analysesAreError = results.some((result) => result.isError)
  const error = results.find((result) => result.error)?.error

  const prevResultData = useRef<any[]>([])

  useEffect(() => {
    if (results.length) {
      if (!analysesAreError && !analysesAreLoading) {
        setHiddenPage(page + 1)
      }
    }
  }, [analysesAreLoading, analysesAreError])

  const resultData = useMemo(() => {
    if (analysesAreLoading) return prevResultData.current
    const result =
      activeTab === 3
        ? activeItems
        : results
            .map((result) => result.data)
            .filter(Boolean)
            .slice(0, page * pageSize)
    prevResultData.current = result
    return result
  }, [analysesAreLoading, results])

  const selectedOutlier = useMemo(() => {
    if (!allItems.length) return undefined

    const id = urlParams.get('id')
    if (!id) return null

    return allItems.find((outlier) => outlier.uuid === id) ?? null
  }, [urlParams.get('id'), allItems])

  const activeAnalysesAreLoading = results
    .slice(0, page * pageSize)
    .some((result) => result.isLoading)

  const isPageLoading = results
    .slice(0, page * pageSize)
    .some((result) => result.isLoading)
  const hasMoreOutliers = !!backgroundResults.length

  const handleBackClick = () => {
    urlParams.delete('id')
    mixpanel.track('insights feed', {
      action: 'back',
    })
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const incrementPage = () => {
    setPage(page + 1)
    setHiddenPage(hiddenPage + 1)
    mixpanel.track('insights feed', {
      action: 'load more',
      value: page + 1,
    })
  }

  const resetPageCount = () => {
    setPage(1)
    setHiddenPage(2)
  }

  return (
    <Container>
      <PageDurationTracker pageName={'insights feed'}></PageDurationTracker>
      <Top isCollapsed={!!cohort || !!urlParams.get('id')}>
        <Content>
          <Header>
            <Title>
              <TitleWord1>Insights</TitleWord1>
              <TitleWord2>Feed</TitleWord2>
            </Title>
            <Beta>beta</Beta>
          </Header>
          {!urlParams.get('id') && (
            <>
              {!cohort && <Subtitle>Outliers we find for you.</Subtitle>}
              {!cohort && (
                <>
                  <Instructions>
                    Customize your feed by selecting filters below, or simply
                    click 'Find Insights' to view all the outliers we’ve
                    identified!
                  </Instructions>
                  <br></br>
                  <Instructions>
                    Insights are refreshed weekly, so make sure to use the
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <Bookmark
                        style={{
                          color: buttonBlue,
                          marginBottom: -5,
                          fontSize: 20,
                        }}
                      />
                      <span style={{ color: buttonBlue }}>Bookmark</span>
                    </span>{' '}
                    feature on any Insights you would like to revisit again in
                    the future!
                  </Instructions>
                </>
              )}
            </>
          )}
        </Content>
        <Bookmarks isCohortSet={!!cohort || !!urlParams.get('id')} />
      </Top>
      <Bottom isFull={!!cohort || !!urlParams.get('id')}>
        <ContentHeader>
          {urlParams.get('id') ? (
            <BackButton onClick={handleBackClick}>
              <BackSVG></BackSVG>
              <div>Back to List</div>
            </BackButton>
          ) : (
            <Controls
              setCohort={setCohort}
              resetPageCount={resetPageCount}
              additionalFilters={additionalFilters}
              setAdditionalFilters={setAdditionalFilters}
            />
          )}
        </ContentHeader>
        <Divider>
          <CoolDividerSVG />
        </Divider>
        {!cohort && !urlParams.get('id') ? (
          <Graphic>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: '46%',
                transform: 'translateX(-30%) translateY(10%)',
              }}
            >
              <LandingPageSVG />
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: '47%',
                transform: 'translateX(-53%) translateY(26%)',
              }}
            >
              <img src={spotGif} style={{ maxWidth: 400 }} />
            </div>
          </Graphic>
        ) : (
          <>
            {!!urlParams.get('id') && (
              <>
                {selectedOutlier ? (
                  <InsightDetail
                    outlierRecord={selectedOutlier}
                    filterValues={filterValues}
                  />
                ) : (
                  <DetailLoadingWrapper>
                    {selectedOutlier === null ? (
                      <LoaderTitle>
                        <ExpiredInsightMessage>
                          Unable to find the selected Insight.
                          <br></br>
                          <br></br>
                          <img src={postsEmpty} />
                          <br></br>
                          <br></br>
                          Insights are refreshed weekly, so make sure to use the{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <Bookmark
                              style={{ color: buttonBlue, marginBottom: -5 }}
                            />
                            <span style={{ color: buttonBlue }}>Bookmark</span>
                          </span>{' '}
                          feature on any Insights you would like to revisit
                          again in the future!
                          <br></br>
                          <br></br>
                          We plan to make historical insights easier to return
                          to as we continue to develop this feature, and until
                          then we thank you for your patience.
                          <br></br>
                          <br></br>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <StartButton
                              onClick={handleBackClick}
                              style={{
                                width: 'fit-content',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                              }}
                            >
                              <BackSVG></BackSVG> Back to Insights
                            </StartButton>
                          </div>
                        </ExpiredInsightMessage>
                      </LoaderTitle>
                    ) : (
                      <>
                        <LogoSpinner height={200} />
                        <LoaderTitle>Loading Insight</LoaderTitle>
                      </>
                    )}
                  </DetailLoadingWrapper>
                )}
              </>
            )}

            <Body
              style={{
                height: !!urlParams.get('id') ? 0 : '100%',
              }}
            >
              <InsightList
                outliers={resultData as OutlierType[]}
                selectedOutlier={
                  selectedOutlier ?? (resultData as OutlierType[])[0]
                }
                incrementPage={incrementPage}
                isPageLoading={isPageLoading}
                hasMoreOutliers={hasMoreOutliers}
                search={search}
                isLoading={
                  ((analysesAreLoading || !outlierResponse) &&
                    !resultData.length) ||
                  filterListLoading
                }
              />
              {(((analysesAreLoading || !outlierResponse) &&
                !resultData.length) ||
                filterListLoading) && (
                <Overlay>
                  <LogoSpinner height={200} />
                </Overlay>
              )}
            </Body>
          </>
        )}
      </Bottom>
    </Container>
  )
}

export default Insights

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: -110px;
  height: calc(100% + 89px);
  padding: 64px 32px 0;

  background: linear-gradient(
    90deg,
    rgba(255, 112, 85, 0.1) 0%,
    rgba(252, 250, 250, 0.064) 43.62%
  );

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  height: ${({ isCollapsed }) => (isCollapsed ? '15%' : 'auto')};
  justify-content: space-between;
`

const Content = styled.div`
  margin-left: 19px;
`

const Header = styled.div`
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  align-items: start;
`

const Title = styled.div`
  font-size: 44px;
  color: ${lightBlueFontColor};
  position: relative;
`

const Beta = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${primaryOrange};
  margin-top: 12px;
  margin-left: 2px;
  font-weight: 300;
`

const Subtitle = styled.div`
  font-size: 22px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 18px;
`

const Instructions = styled.div`
  font-size: 14px;
  line-height: 25px;
  color: #7e7d7d;
  max-width: 400px;
`

const Bottom = styled.div<{ isFull: boolean }>`
  position: relative;
  height: 100%;
  max-height: ${({ isFull }) => (isFull ? 'calc(100% - 165px)' : '50%')};

  width: calc(100% + 69px);
  margin-left: -37px;
  background: white;

  border: 2px solid #dddddd;
  border-radius: 10px;

  padding: 17px;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  box-shadow: 0 4px 10px 0 #00000026;
`

const Graphic = styled.div`
  margin-top: 50px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  svg {
    max-height: 75%;
    max-width: 75%;
  }
`

const TitleWord1 = styled.span`
  font-weight: 500;
  color: ${darkBlueFontColor};
`

const TitleWord2 = styled.span`
  font-weight: 300;
`

const ContentHeader = styled.div`
  z-index: 5;
`

const BackButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 5px 15px 5px 34px;

  font-size: var(--font-size-xl);

  color: ${primaryOrange};
  background: linear-gradient(90deg, #ff705533 0%, rgba(255, 112, 85, 0) 100%);

  border-radius: 20px;

  cursor: pointer;
`

const Divider = styled.div`
  margin-top: -8px;
`

const Body = styled.div`
  position: relative;
  overflow: auto;
  margin-top: -7px;
  height: 100%;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding-top: 10%;
  display: flex;
  justify-content: center;
  //align-items: center;
  min-height: 500px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background: white;
`

const DetailLoadingWrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  margin-top: -7px;
  padding-top: 50px;

  background: ${pageBackgroundGrey};
  border-radius: 10px;

  overflow: auto;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const ExpiredInsightMessage = styled.div`
  max-width: 50%;
  margin: 8px auto 0;
  text-align: center;
  background: white;
  padding: 30px;
  border: 2px solid ${buttonBlue};
  border-radius: 10px;
`
