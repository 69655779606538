import React, { useState, useEffect, useMemo } from 'react'
import { Select } from 'antd'
import { Filters, ProjectState } from '../../../../../types'
import { HardcodedFilterOptionsEnum } from '../../../../filters/helpers'
import {
  FilterOption,
  FilterOptionDate,
  FilterOptionRange,
} from '../../../../filters/types'
import { DatePanel } from '../../../../filters/features/date-panel/date-panel'
import mixpanel from '../../../../../../trackers/mixpanel'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { OldDatePanel } from '../../../../filters/features/date-panel/old-date-panel'
import { findDateWindow } from '../../../../../utils'

const { Option } = Select

type TimeFrame =
  | 'Week'
  | 'Month'
  | 'Quarter'
  | 'Half'
  | 'Year'
  | 'YTD'
  | 'Trailing'
  | 'Custom Date'

export interface DateRange {
  label: string
  value: string
  type?: TimeFrame
  daysCount?: number
}

let TIME_FRAMES: TimeFrame[] = [
  'Trailing',
  'Week',
  'Month',
  'Quarter',
  'Half',
  'Year',
  'YTD',
  'Custom Date',
]

type Props = {
  filterValues: Filters
  updateLocalFilterValues: (filterValues: Filters) => void
  excludeCustom?: boolean
  isDashboard?: boolean
  isEdit?: boolean
  disabled?: boolean
  isCurrent?: boolean
  setTimeResolution?: (timeResolution: string) => void
  setTrailingDaysCount?: (timeResolution: number) => void
}

const DashboardDateSelect: React.FC<Props> = ({
  filterValues,
  updateLocalFilterValues,
  excludeCustom,
  isDashboard,
  isEdit,
  disabled,
  isCurrent,
  setTimeResolution,
  setTrailingDaysCount,
}) => {
  let timeFrames = [...TIME_FRAMES]
  if (excludeCustom || isCurrent) {
    timeFrames = timeFrames.filter((timeFrame) => timeFrame !== 'Custom Date')
  }
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore((state) => state.defaultFilterList)
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const [enabled, setEnabled] = useState<boolean>(false)
  const [dates, setDates] = useState<DateRange[]>([])

  useEffect(() => {
    if (isEdit) {
      setEnabled(false)
    }
  }, [isEdit])

  const dateFilter = useMemo(
    () =>
      filterValues.values.find(
        ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME,
      ) as FilterOptionDate | undefined,
    [filterValues],
  )

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>('Month')
  const [selectedDateRange, setSelectedDateRange] = useState<
    string | undefined
  >(
    !!dateFilter && excludeCustom
      ? JSON.stringify({ min: dateFilter.min, max: dateFilter.max })
      : undefined,
  )

  useEffect(() => {
    setDates(dateWindows[selectedTimeFrame])
  }, [selectedTimeFrame])

  useEffect(() => {
    if (dateWindows) {
      const dateWindow = findDateWindow(
        dateWindows,
        dateFilter?.min,
        dateFilter?.max,
      )
      if (dateWindow?.type) {
        setSelectedTimeFrame(dateWindow?.type as TimeFrame)
      } else {
        setSelectedTimeFrame(excludeCustom ? 'Trailing' : 'Custom Date')
      }
    }
  }, [dateFilter, dateWindows])

  useEffect(() => {
    // this is so when you hit the reset button it loads back in without causing an infinite loop
    setEnabled(false)
    setSelectedDateRange(
      !!dateFilter && !excludeCustom
        ? JSON.stringify({ min: dateFilter.min, max: dateFilter.max })
        : undefined,
    )
  }, [filterValues])

  // TODO move this to the handler, shouldnt be an effect
  useEffect(() => {
    if (!enabled) {
      setEnabled(true)
      return
    }
    if (selectedDateRange === undefined) return
    updateLocalFilterValues({
      values: [
        ...filterValues.values.filter(
          ({ field }) => field !== HardcodedFilterOptionsEnum.CREATE_TIME,
        ),
        {
          field: HardcodedFilterOptionsEnum.CREATE_TIME,
          min: JSON.parse(selectedDateRange).min,
          max: JSON.parse(selectedDateRange).max,
          value_type: 0, // TODO??
        },
      ],
      searchCondition: filterValues.searchCondition,
      searchQuery: filterValues.searchQuery,
    })
    if (selectedTimeFrame === 'Trailing' && selectedDateRange) {
      const dateWindow = findDateWindow(
        dateWindows,
        JSON.parse(selectedDateRange).min,
        JSON.parse(selectedDateRange).max,
      )
      if (dateWindow?.daysCount) {
        setTrailingDaysCount && setTrailingDaysCount(dateWindow.daysCount)
      }
    }
  }, [selectedDateRange])

  return (
    <div style={{ display: 'flex', gap: 10, width: '100%' }}>
      <Select
        value={selectedTimeFrame}
        onChange={(value) => {
          mixpanel.track('timeframe select', {
            action: 'copy',
            value,
            proj_uuid: projectId,
          })
          setSelectedTimeFrame(value as TimeFrame)
          setSelectedDateRange(undefined)
          if (setTimeResolution) {
            setTimeResolution(value as string)
          }
        }}
        style={{ minWidth: 150 }}
        disabled={disabled}
      >
        {timeFrames.map((frame) => (
          <Option key={frame} value={frame} className={'option-' + frame}>
            {frame}
          </Option>
        ))}
      </Select>
      {!!dates ? (
        <Select
          placeholder={'Select date range'}
          style={{ width: '100%', minWidth: 200 }}
          value={selectedDateRange}
          onChange={(value) => {
            mixpanel.track('date range', {
              value: value,
            })
            setEnabled(true)
            setSelectedDateRange(value)
          }}
          disabled={disabled || (isCurrent && selectedTimeFrame !== 'Trailing')}
        >
          {dates.map((date) => (
            <Option key={date.label} value={date.value}>
              {date.label}
            </Option>
          ))}
        </Select>
      ) : (
        <>
          {isDashboard ? (
            <OldDatePanel
              comparativeIndex={0}
              filterValues={filterValues}
              updateLocalFilterValues={updateLocalFilterValues}
              parentMin={
                (
                  defaultFilterList.find(
                    ({ field }) =>
                      field === HardcodedFilterOptionsEnum.CREATE_TIME,
                  ) as FilterOptionRange
                )?.min
              }
              parentMax={
                (
                  defaultFilterList.find(
                    ({ field }) =>
                      field === HardcodedFilterOptionsEnum.CREATE_TIME,
                  ) as FilterOptionRange
                )?.max
              }
              disabled={disabled}
            />
          ) : (
            <DatePanel
              comparativeIndex={0}
              filterValues={filterValues}
              updateLocalFilterValues={updateLocalFilterValues}
            />
          )}
        </>
      )}
    </div>
  )
}

export default DashboardDateSelect
