import React from 'react'
import { Section } from '../../InsightDetail'
import styled from 'styled-components'
import {
  buttonBlue,
  darkBlueFontColor,
  mainText,
  primaryOrange,
} from 'assets/styles/variables'

type Props = {
  sections: Section[]
  scrollToSection: (id: string) => void
  activeSection: string
  activeSubsection: string
}

export const TableOfContents: React.FC<Props> = ({
  sections,
  scrollToSection,
  activeSection,
  activeSubsection,
}) => {
  return (
    <Container>
      {sections.map((section) => (
        <Wrapper key={section.id}>
          <Item
            onClick={() => scrollToSection(section.id)}
            active={activeSection === section.id}
            style={{
              marginBottom: section.subsections?.length ? 10 : 15,
            }}
          >
            {section.title}
          </Item>

          {section.subsections?.map((subsection) => (
            <SubItem
              key={subsection.id}
              onClick={() => scrollToSection(subsection.id)}
              active={activeSubsection === subsection.id}
            >
              {subsection.title}
            </SubItem>
          ))}
        </Wrapper>
      ))}
    </Container>
  )
}

const Container = styled.div`
  padding: 20px 20px 0;
`

const Wrapper = styled.div``

const Item = styled.div<{ active: boolean }>`
  line-height: 1.3;
  margin-bottom: 15px;
  color: ${({ active }) => (active ? primaryOrange : darkBlueFontColor)};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  border-bottom: ${({ active }) =>
    active ? `2px solid ${primaryOrange}` : '2px solid transparent'};

  font-size: 12px;

  width: fit-content;

  cursor: pointer;

  transition: all 0.25ms ease-in-out;

  &:hover {
    color: ${primaryOrange};
  }
`
const SubItem = styled.div<{ active: boolean }>`
  line-height: 1.5;
  margin-bottom: 10px;
  margin-left: 20px;
  color: ${buttonBlue};
  font-size: 12px;
  color: ${({ active }) => (active ? primaryOrange : buttonBlue)};
  // font-weight: ${({ active }) => (active ? 600 : 400)};

  text-decoration: ${({ active }) => (active ? `underline` : 'inherit')};

  width: fit-content;

  cursor: pointer;

  transition: all 0.25ms ease-in-out;

  &:hover {
    color: ${primaryOrange};
  }

  &:last-child {
    margin-bottom: 15px;
  }
`
