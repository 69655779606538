import React, { useEffect } from 'react'
import { autoSignIn } from 'aws-amplify/auth'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from 'features/auth'
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth'
import { home, signinURL } from 'utils/links'
import { Loader } from 'shared/components'
import * as Sentry from '@sentry/react'

export const SignInCallback: React.FC = () => {
  const authContext = useAuthContext()
  const history = useHistory()

  useEffect(() => {
    handleAutoSignIn()
  }, [])

  const handleAutoSignIn = async () => {
    try {
      const signInOutput = await autoSignIn()
      if (signInOutput.isSignedIn) {
        const user = await getCurrentUser()

        authContext.signIn(user)
        history.push(home)
      } else {
        history.push(signinURL)
      }
    } catch (e) {
      Sentry.captureException(e)
      history.push(signinURL)
    }
  }

  return <Loader />
}
