import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  ChartTypes,
  matrixChartIds,
  setDefaultFontSize,
} from 'features/project/features/Charts/utils'
import {
  type BarChart,
  type BubbleChart,
  DataForChart,
  type LineChart,
  type PieChart,
  type StackBarChart,
  type UnionChart,
} from 'features/project/features/Charts/types'
import {
  configureBarChart,
  configureBubbleChart,
  configureLineChart,
  configurePieChart,
  configureStackBarChart,
  configureTreemapChart,
} from 'features/project/features/Charts/features'
import { configureMatrixChart } from 'features/project/features/Charts/features/matrix'
import { PaginationProps } from 'antd'
import mixpanel from '../../trackers/mixpanel'
import _ from 'lodash'
import { useChartData } from './useChartData'
import { useFeatureFlags } from './useFeatureFlags'
import { useProjectStore } from '../projectStore/projectStore'
import { Filters, ProjectState } from '../types'
import { AuthContext } from '../../auth'
import { usePrevFilters } from './usePrevFilters'

type Props = {
  currentChart: UnionChart
  chartRef: React.RefObject<HTMLCanvasElement>
  dashboardControls: any
  comparativeIndex: number
  isComparative: boolean
  comparativePanelsNumber: number
  filters: Filters
  updateDashboardControls: (field: string, value: any) => void
  filteredExpandedCategories: string[]
  disabledCategories: string[]
  disableGrouping?: boolean
  priorityItem?: string
}
const useChartConfig = ({
  currentChart,
  chartRef,
  dashboardControls,
  comparativeIndex,
  isComparative,
  comparativePanelsNumber,
  filters,
  updateDashboardControls,
  filteredExpandedCategories,
  disabledCategories,
  disableGrouping,
  priorityItem,
}: Props) => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const updateDashboardControlsGlobal = useProjectStore(
    (state) => state.updateDashboardControls,
  )
  const currentChartList = useProjectStore(
    (state: ProjectState) => state.currentChartList,
  )

  const {
    fullAxis,
    fontSize,
    hasLabels,
    hasOverlay,
    treeView,
    origin,
    trendlines,
    shadedZones,
    sortedByCategory,
    multiChart,
    tier1,
    isAlignOrder,
    isAlignScales,
    timeInterval,
    postType,
    wrapLabels,
    maxAxesLength,
    matrixScaleVolume,
    matrixSort,
    matrixSortAxis,
    matrixSortValue,
    matrixSpectrumColors,
    matrixFlipAxis,
    hideParentCategories,
    hideXAxis,
    hideYAxis,
  } = dashboardControls[0]

  const {
    order,
    isDelta,
    pageSize,
    expandedCategories,
    isFullScreen,
    comparePeriod,
    showCategories,
  } = dashboardControls[comparativeIndex] ?? {}

  useEffect(() => {
    if (!expandedCategories?.length) {
      setCurrentPage(1)
      setPaginationMin(0)
    }
  }, [expandedCategories])

  const { data: feature_flags } = useFeatureFlags()

  const authContext = useContext(AuthContext)
  const isYogi = authContext.user?.attributes?.email.includes('@meetyogi.com')

  const { dateFilter, prevFilters, prevDateIsBeforeFirstReview } =
    usePrevFilters({
      filters,
      dateWindows,
      defaultFilterList,
      prevIsDelta: !!isDelta,
      comparePeriod,
    })

  const enablePreviousChart =
    (currentChart.chart_type === ChartTypes.LINE && !!comparePeriod) ||
    (currentChart.chart_type === ChartTypes.BAR && comparePeriod)

  // note of weird behavior - even when disabled=true, if the query key matches
  // an active query (ie - no compare period set), it will still magically
  // return data against your wishes. because of this, we conditionally supply
  // prevChartData to the chart configuration hook
  const {
    data: prevChartData,
    isLoading: prevLoading,
    isError: prevError,
  } = useChartData(
    0,
    prevFilters,
    tier1,
    currentChart.chart_id,
    postType,
    timeInterval,
    0,
    !filters ||
      !dateFilter ||
      !enablePreviousChart ||
      !feature_flags?.yoy_charts,
  )

  const [currentConfiguredChart, setCurrentConfiguredChart] = useState<
    DataForChart | null | undefined
  >()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationMin, setPaginationMin] = useState<number>(0)

  const onPageChange: PaginationProps['onChange'] = (page, newPageSize) => {
    if (pageSize !== newPageSize) {
      mixpanel.track('custom dashboard', {
        action: 'page size',
        value: newPageSize,
      })
      isNaN(newPageSize)
        ? // @ts-ignore
          updateDashboardControls('pageSize', undefined)
        : updateDashboardControls('pageSize', newPageSize)
      setCurrentPage(1)
      setPaginationMin(0)
    } else {
      setPaginationMin(pageSize ? (page - 1) * pageSize : 1)
      setCurrentPage(page)
    }
  }

  // yeah, yeah
  // useEffect(() => {
  //   if (localPaginationMin !== undefined) {
  //     setPaginationMin(localPaginationMin)
  //   }
  // }, [localPaginationMin])

  const toggleOrder = () => {
    setCurrentPage(1)
    setPaginationMin(0)
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    mixpanel.track('custom dashboard', {
      action: 'bar chart order',
      value: newOrder,
    })
    updateDashboardControls('order', newOrder)
    // line up other panel orders if relevant
    if (isAlignOrder && comparativeIndex === 0 && comparativePanelsNumber > 1) {
      _.times(comparativePanelsNumber - 1, (i) => {
        updateDashboardControlsGlobal(i + 1, 'order', newOrder)
      })
    }
  }

  useEffect(() => {
    if (currentChart && chartRef.current) {
      setDefaultFontSize(fontSize)
      const chartType = currentChart.chart_type
      let result: DataForChart | null = null
      if (
        chartType === ChartTypes.MATRIX ||
        matrixChartIds.includes(chartType)
      ) {
        result = configureMatrixChart(
          currentChart as BarChart,
          tier1,
          showCategories,
          matrixScaleVolume,
          matrixSort,
          matrixSortAxis,
          matrixSortValue,
          matrixSpectrumColors,
          matrixFlipAxis,
          expandedCategories,
          isComparative,
          fullAxis,
          wrapLabels,
          maxAxesLength,
          disableGrouping,
          // no disable grouping cause brands/products aren't grouped currently, just themes
        )
      } else if (chartType === ChartTypes.BAR) {
        const localCurrentChart = {
          ...currentChart,
          values: [...(currentChart as BarChart).values],
        }
        result = configureBarChart(
          localCurrentChart as BarChart,
          filters,
          dateWindows,
          tier1,
          showCategories,
          hasLabels,
          fullAxis,
          isComparative,
          paginationMin,
          pageSize,
          wrapLabels,
          maxAxesLength,
          filteredExpandedCategories,
          order,
          sortedByCategory,
          isAlignOrder,
          comparativePanelsNumber,
          hideXAxis && isYogi,
          hideYAxis && isYogi,
          enablePreviousChart
            ? (prevChartData?.charts[0] as BarChart)
            : undefined,
          prevFilters,
          disableGrouping,
          priorityItem,
        )
      } else if (chartType === ChartTypes.BUBBLE) {
        // pressing back from charts to go to home, then forward to go back to charts was whitescreening, no clue why
        if ((currentChart as BubbleChart)?.values) {
          result = configureBubbleChart(
            currentChart as BubbleChart,
            tier1,
            showCategories,
            filteredExpandedCategories,
            hasLabels,
            shadedZones,
            trendlines,
            origin,
            hideXAxis && isYogi,
            hideYAxis && isYogi,
            disableGrouping,
          )
        }
      } else if (chartType === ChartTypes.LINE) {
        result = configureLineChart({
          currentChart: currentChart as LineChart,
          tier1,
          showCategories,
          hasLabels,
          shadedZones,
          trendlines,
          fullAxes: fullAxis,
          isMultiChart: multiChart,
          expandedCategories: filteredExpandedCategories,
          hideXAxis: hideXAxis && isYogi,
          hideYAxis: hideYAxis && isYogi,
          prevChart: enablePreviousChart
            ? (prevChartData?.charts[0] as LineChart)
            : undefined,
          comparePeriod,
          timeInterval,
          prevDateIsBeforeFirstReview,
          disableGrouping,
        })
      } else if (chartType === ChartTypes.STACK_BAR) {
        result = configureStackBarChart(
          currentChart as StackBarChart,
          tier1,
          showCategories,
          fullAxis,
          hasLabels,
          isComparative,
          paginationMin,
          pageSize,
          wrapLabels,
          maxAxesLength,
          filteredExpandedCategories,
          order,
          sortedByCategory,
          isAlignOrder,
          comparativePanelsNumber,
          hideXAxis && isYogi,
          hideYAxis && isYogi,
          disableGrouping,
        )
      } else if (chartType === ChartTypes.PIE) {
        result = treeView
          ? configureTreemapChart(
              currentChart as PieChart,
              tier1,
              showCategories,
              filteredExpandedCategories,
              disableGrouping,
            )
          : configurePieChart(
              currentChart as PieChart,
              tier1,
              showCategories,
              filteredExpandedCategories,
              hasLabels,
              wrapLabels,
              maxAxesLength,
              disableGrouping,
            )
      }

      if (result) {
        result.type_label = currentChart.chart_type
        result.legend = result.legend.map((el) => {
          if (disabledCategories.includes(el.label)) {
            return {
              ...el,
              disabled: true,
            }
          }
          return el
        })
        setCurrentConfiguredChart(result)
        // const {
        //   data: dataObject,
        //   options,
        //   type,
        //   legend: legendObject,
        //   plugins,
        // } = result
        //
        // const newPlugins = plugins
        //   ? [...plugins, contextMenuPlugin]
        //   : [contextMenuPlugin]
        //
        // if (chartAPI) {
        //   chartAPI.destroy()
        // }
        //
        // const chart = new ChartJS(chartRef.current, {
        //   type,
        //   options,
        //   data: dataObject,
        //   plugins: newPlugins as any,
        // })
        //
        // setChartAPI(chart)
        //
        // if (prevCurrentChart !== currentChart || !legend) {
        //   setLegend(legendObject)
        // } else {
        //   setLegend(legendObject)
        //   setTriggerUpdate(!triggerUpdate)
        // }
        // setPrevCurrentChart(currentChart)
      }
    } else {
      setCurrentConfiguredChart(null)
      // setLegend(null)
    }
  }, [
    currentChart,
    fontSize,
    fullAxis,
    hasLabels,
    hasOverlay,
    shadedZones,
    treeView,
    trendlines,
    origin,
    sortedByCategory,
    multiChart,
    paginationMin,
    pageSize,
    order,
    wrapLabels,
    maxAxesLength,
    matrixScaleVolume,
    matrixSort,
    matrixSortAxis,
    matrixSortValue,
    matrixSpectrumColors,
    matrixFlipAxis,
    filteredExpandedCategories,
    isFullScreen,
    hideParentCategories,
    hideXAxis,
    hideYAxis,
    prevChartData,
    multiChart,
    disabledCategories,
    showCategories,
  ])

  return {
    currentConfiguredChart,
    currentPage,
    setCurrentPage,
    toggleOrder,
    onPageChange,
    isPreviousChartLoading: prevLoading,
  }
}

export default useChartConfig
