import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { FilterOptionSelect } from '../features/filters/types'

const useThemes = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const details = useProjectStore((state: ProjectState) => state.details)

  const isLLMDash = !!details.clientSettings['llm-dash']

  const getSubthemeNameFromClusterUUID = (uuid: string) => {
    return (
      defaultFilterList.find(
        (item) => item.field === 'theme',
      ) as FilterOptionSelect
    )?.values
      .find((el) => el.cluster_uuid === uuid)
      ?.value?.split(': ')[1]
  }

  const getClusterUUIDsFromThemeName = (theme: string, subtheme: string) => {
    return (
      (
        defaultFilterList.find(
          (item) => item.field === 'theme',
        ) as FilterOptionSelect
      )?.values
        ?.filter((el) => el.value === `${theme}: ${subtheme}`)
        ?.map((el) => el.cluster_uuid ?? el.value) ?? [`${theme}: ${subtheme}`]
    )
  }

  const getNormalizedThemeName = (theme: string) => {
    if (theme.split(': ')?.[0] === theme.split(': ')?.[1]) {
      return theme.split(': ')[0]
    }
    return theme
  }

  const convertThemesToClusterUUIDs = (themeNames: string[]) => {
    return themeNames
      .map((name: string) => {
        // theme labels take the format [theme]: [subtheme]
        // uuids are xxxxxx-xxxxxx-xxxxxx-xxxxxx
        // just checking to see what format the theme has been passed in as
        if (name.split(': ').length < 2) return name
        return getClusterUUIDsFromThemeName(
          name.split(': ')[0],
          name.split(': ')[1],
        )
      })
      .flat()
  }

  return {
    getSubthemeNameFromClusterUUID,
    getClusterUUIDsFromThemeName,
    getNormalizedThemeName,
    convertThemesToClusterUUIDs,
  }
}

export default useThemes
