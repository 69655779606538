import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography, Form as antdForm, Row, Modal } from 'antd'
import { Input, InputPassword } from '../components/input'
import { Button } from '../components/button'
import { FormItem } from '../components/form_item'
import { ServerValidationError } from '../components/server_validation_error'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Loader } from 'shared/components'
import { resetPasswordURL, setupPasswordURL, home } from 'utils/links'
import { AuthContext } from 'features/auth'
import { AuthWrapper } from 'shared/wrappers'
import { updateLastVisit } from '../model'
import {
  getCurrentUser,
  fetchUserAttributes,
  signInWithRedirect,
} from '@aws-amplify/auth'
import oktaImg from 'assets/images/Okta-Logo.png'
import azureImg from 'assets/images/Azure-Logo.png'
import pingImg from 'assets/images/Ping-Logo.png'
import { YogiButton } from 'components/UI/YogiButton'
import { cardBorderGrey } from 'assets/styles/variables'
import { YogiInput } from 'components/UI/YogiInput'
import { signIn } from '@aws-amplify/auth'

const layout = {
  layout: 'vertical',
}

const SignInSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(2, 'Too Short!').required('Required'),
})

export const SignInForm = () => {
  const federatedSignInOkta = async () => {
    return signInWithRedirect({ provider: { custom: 'Okta' } })
  }
  const federatedSignInOktaMars = async () => {
    return signInWithRedirect({ provider: { custom: 'OktaMARS' } })
  }

  const federatedSignInAzure = async () => {
    await signInWithRedirect({ provider: { custom: 'AzureActiveDirectory' } })
  }

  const [oktaModalOpen, setOktaModalOpen] = useState(false)
  const [oktaEmail, setOktaEmail] = useState('')

  const [pingModalOpen, setPingModalOpen] = useState(false)
  const [pingEmail, setPingEmail] = useState('')
  const [showPingWarning, setShowPingWarning] = useState(false)

  const checkOktaEmail = (email) => {
    if (email.toLowerCase().match('.*@effem.com')) {
      federatedSignInOktaMars()
    } else {
      federatedSignInOkta()
    }
  }

  const checkPingEmail = (email) => {
    if (email.toLowerCase().match('.*@mdlz.com')) {
      setPingModalOpen(false)
      federatedSignInPing()
    } else {
      setShowPingWarning(true)
    }
  }

  const federatedSignInPing = async () => {
    return signInWithRedirect({ provider: { custom: 'Ping' } })
  }

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [serverValidationError, setServerValidationError] = useState('')
  const authContext = useContext(AuthContext)

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('redirect')) {
      history.push(searchParams.get('redirect'))
    } else {
      history.push(home)
    }
  }

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: SignInSchema,
    onSubmit: async ({ username, password }) => {
      try {
        setLoading(true)

        window.localStorage.clear()

        const { isSignedIn, nextStep } = await signIn({
          username: username.toLowerCase(),
          password,
        })

        if (username.indexOf('@meetyogi.com') !== -1) {
          window.ga('set', 'dimension1', 'yes')
        }

        const user = await getCurrentUser()

        if (
          nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          authContext.setUser(user)
          history.push(setupPasswordURL)
          return
        }

        if (isSignedIn) {
          setTimeout(() => {
            updateLastVisit()
          }, 3000)

          const attributes = await fetchUserAttributes()

          authContext.signIn(user)
          handleRedirect()
          toast.success(`Welcome back, ${attributes?.given_name}!`)
        }
      } catch (e) {
        setLoading(false)
        setServerValidationError(e.message)
      }
    },
  })

  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:show'])
    window.$crisp.push([
      'on',
      'chat:closed',
      () => {
        window.$crisp.push(['do', 'chat:show'])
      },
    ])
  }

  const location = useLocation()

  // Might need this useEffect to catch
  useEffect(() => {
    if (authContext.isAuthenticated) {
      handleRedirect()
    }
  }, [authContext.isAuthenticated])

  const usernameError = formik.touched.username && formik.errors.username
  const passwordError = formik.touched.password && formik.errors.password

  const onChange = (e) => {
    setServerValidationError('')
    formik.handleChange(e)
  }

  return (
    <AuthWrapper>
      <Wrapper>
        <Form onFinish={formik.submitForm} {...layout}>
          <FormTitle>Log in to Yogi</FormTitle>
          <StyledFormItem
            label="Email"
            validateStatus={usernameError && 'error'}
            help={formik.touched.username && formik.errors.username}
          >
            <Input
              name="username"
              autoComplete="username"
              placeholder="john.smith@acmelabs.com"
              onChange={onChange}
              value={formik.values.username}
              style={{
                border: '2px solid ' + cardBorderGrey,
                borderRadius: '6px',
              }}
            />
          </StyledFormItem>
          <StyledPassword
            label="Password"
            validateStatus={passwordError && 'error'}
            help={passwordError}
          >
            <InputPassword
              name="password"
              placeholder="********"
              autoComplete="current-password"
              onChange={onChange}
              value={formik.values.password}
            />
          </StyledPassword>

          <RowHelpers>
            <LinkForgot to={resetPasswordURL}>Forgot password?</LinkForgot>
          </RowHelpers>
          {serverValidationError && (
            <ServerValidationError message={serverValidationError} />
          )}
          <Action style={{ marginBottom: 0 }}>
            <YogiButton
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
                maxWidth: '273px',
                margin: '0 auto',
                height: '50px',
              }}
            >
              Log In
            </YogiButton>
          </Action>
          <>
            <Divider>
              <span>Or log in with</span>
            </Divider>
            <Row>
              <SSOButton block onClick={federatedSignInAzure}>
                <AzureLogo src={azureImg} alt="azure logo" />
              </SSOButton>
            </Row>
            <Row>
              <SSOButton block onClick={() => setOktaModalOpen(true)}>
                <OktaLogo src={oktaImg} alt="okta logo" />
              </SSOButton>
            </Row>
            <Row>
              <SSOButton block onClick={() => setPingModalOpen(true)}>
                <PingLogo src={pingImg} alt="ping logo" />
              </SSOButton>
            </Row>
          </>
        </Form>

        <SignUp>
          {"Don't have deep insight into your shopper sentiment?"}{' '}
          <a href="https://meetyogi.com/book-a-demo">Sign up</a>
        </SignUp>
        {loading && (
          <Overlay>
            <LoadingContent>
              <Loader />
            </LoadingContent>
          </Overlay>
        )}
      </Wrapper>
      <Modal
        title={'Enter Okta email'}
        open={oktaModalOpen}
        footer={
          <YogiButton
            type={'primary'}
            onClick={() => checkOktaEmail(oktaEmail)}
          >
            Submit
          </YogiButton>
        }
        onCancel={() => setOktaModalOpen(false)}
      >
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              checkOktaEmail(oktaEmail)
            }}
          >
            <div style={{ display: 'flex', gap: 10 }}>
              <YogiInput
                placeholder={'Email address'}
                value={oktaEmail}
                onChange={(e) => setOktaEmail(e.target.value)}
              />
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        title={'Enter email for Ping Identity'}
        open={pingModalOpen}
        footer={
          <YogiButton
            type={'primary'}
            onClick={() => checkPingEmail(pingEmail)}
          >
            Submit
          </YogiButton>
        }
        onCancel={() => setPingModalOpen(false)}
      >
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              checkPingEmail(pingEmail)
            }}
          >
            <div style={{ display: 'flex', gap: 10 }}>
              <YogiInput
                placeholder={'Email address'}
                value={pingEmail}
                onChange={(e) => setPingEmail(e.target.value)}
              />
            </div>
          </form>
          {showPingWarning && (
            <p style={{ color: 'red', marginTop: 10, marginBottom: 0 }}>
              Your organization does not support this SSO option with Yogi at
              this time.
            </p>
          )}
        </div>
      </Modal>
    </AuthWrapper>
  )
}

// Styled components remain unchanged
const SSOButton = styled(Button)`
  height: 47px;
  background: #f0f7fe;
  border: 1px solid #d4e5fe;
  border-radius: 3px;
  margin-top: 14px;
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  color: var(--color-auth-grey);
  background: #fff;
`

const Divider = styled.div`
  overflow: hidden;
  text-align: center;
  padding: 35px 0px 15px 0;
  color: #9ca8bb;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  :before,
  :after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 1px;
    background-color: #9ca8bb;
    position: relative;
  }

  :before {
    margin-left: -100%;
    left: -27px;
  }

  :after {
    margin-right: -100%;
    right: -27px;
  }
`

const Form = styled(antdForm)`
  position: relative;
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 45px;

  border: 2px solid var(--card-border-grey);
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 35px;
    border: none;
  }
`

const FormTitle = styled.h2`
  margin-bottom: 45px;
  text-align: center;
  color: var(--color-auth-grey);
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`

const RowHelpers = styled(Row)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;

  span {
    font-size: 12px;
  }
`

const Action = styled(Row)`
  margin-top: 20px;
`

const LinkForgot = styled(Link)`
  margin-left: auto;
  font-weight: 600;
  text-decoration: underline;
  color: var(--color-auth-grey);
`

const OktaLogo = styled.img`
  height: 36px;
  width: 66px;
`

const AzureLogo = styled.img`
  height: 22px;
  width: 80px;
`

const PingLogo = styled.img`
  height: 20px;
  width: 94px;
`

const SignUp = styled(Typography.Text)`
  display: block;
  max-width: 400px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
  color: var(--color-auth-grey);

  a {
    text-decoration: underline;
  }
`

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 5;
`

const LoadingContent = styled.div`
  position: absolute;
  top: 200px;
`

const StyledFormItem = styled(FormItem)`
  .ant-input {
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`

const StyledPassword = styled(FormItem)`
  .ant-input {
    background: white;
  }

  .ant-input-password {
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`
