import React, { useState } from 'react'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import styled from 'styled-components'
import SelectItem from '../FilterPanelSelector/components/SelectItem/SelectItem'
import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
  ApplyGroupFilterPayload,
  FilterOptionValue,
} from '../../../../../types'
import NestedThemeInput from './components/NestedThemeInput'
import useThemes from 'features/project/hooks/useThemes'

type Props = {
  themes: FilterOptionValue[]
  floatValues: boolean
  currentAppliedFilter: AppliedFilterOption | undefined
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  isLoading: boolean
}

const NestedThemes: React.FC<Props> = ({
  themes,
  floatValues,
  applySelect,
  currentAppliedFilter,
  isLoading,
}) => {
  const { convertThemesToClusterUUIDs } = useThemes()
  const topLevelThemes = new Set()
  const subThemeMap: Record<string, FilterOptionValue[]> = {}

  themes.forEach((el) => {
    const splitName = el.value.split(': ')
    if (splitName.length === 1) {
      topLevelThemes.add(splitName[0])
      subThemeMap[splitName[0]] = [el]
    } else if (splitName.length === 2) {
      topLevelThemes.add(splitName[0])
      if (!subThemeMap[splitName[0]]) {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[0] }]
        } else {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[1] }]
        }
      } else {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]][0].n_posts =
            subThemeMap[splitName[0]][0].n_posts + el.n_posts
          subThemeMap[splitName[0]][0].identifiers = [
            ...subThemeMap[splitName[0]][0].identifiers,
            ...el.identifiers,
          ]
        } else {
          subThemeMap[splitName[0]].push({ ...el, value: splitName[1] })
        }
      }
    } else {
      // bad theme name do nothing
    }
  })

  // TODO maybe split this into two sets - with reviews / without reviews to mimic original behavior
  const orderedKeys = floatValues
    ? Object.keys(subThemeMap).sort((a, b) => {
        return (
          subThemeMap[b].reduce(
            (acc: number, el: FilterOptionValue) => acc + el.n_posts,
            0,
          ) -
          subThemeMap[a].reduce(
            (acc: number, el: FilterOptionValue) => acc + el.n_posts,
            0,
          )
        )
      })
    : Object.keys(subThemeMap)

  // the above code makes the cluster uuids the 'value' param of the items in the list
  // however, its still possible that a filter can be provided with the 'values' list
  // of a theme filter be populated with 'label' type values
  // (eg "Battery Life & Charging: Charging Speed" instead of [cluster uuid]
  // I think the best solution for this issue in the current setup is to take
  // the filter object and force the values to be in the form of [cluster uuid]
  // which we do thusly
  const appliedFilter: AppliedFilterOptionSelect | undefined =
    currentAppliedFilter && {
      ...(currentAppliedFilter as AppliedFilterOptionSelect),
      values: convertThemesToClusterUUIDs(
        (currentAppliedFilter as AppliedFilterOptionSelect).values,
      ),
    }

  return (
    <div>
      {orderedKeys.map((theme) => (
        <NestedThemeInput
          key={theme}
          theme={theme}
          subthemes={subThemeMap[theme]}
          currentAppliedFilter={appliedFilter}
          applySelect={applySelect}
          isLoading={isLoading}
        />
      ))}
    </div>
  )
}

export default NestedThemes
