import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getCurrentUser,
  signOut as amplifySignOut,
  fetchAuthSession,
  fetchUserAttributes,
} from '@aws-amplify/auth'
import { User, AuthContextInterface, UserDetailsResponse } from '../types'
import { Loader } from 'shared/components'
import {
  signinURL,
  setupPasswordURL,
  newPasswordURL,
  signinCallbackURL,
  trialSignupURL,
  requestAccessURL,
} from 'utils/links'
import { axiosInstance, setToken, hasTemporaryToken } from 'utils/axios'
import tracker from 'features/trackers/crisp'
import mixpanel from 'features/trackers/mixpanel'
import { fetchUser } from '../model'
import { useQueryClient } from 'react-query'
import { AuthContext } from 'features/auth'
import { AuthUser } from '@aws-amplify/auth'

// Update User type to match Amplify v6 AuthUser if needed
type AmplifyUser = AuthUser & {
  // signInUserSession: {
  //   idToken: {
  //     jwtToken: string
  //   }
  // }
  attributes: {
    email: string
    given_name: string
    family_name: string
    sub: string
    [key: string]: any
  }
}

export const AuthContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<AuthContextInterface['user']>(null)
  const [userDetails, setUserDetails] = useState<UserDetailsResponse | null>(
    null,
  )
  const [authenticated, setAuthenticated] = useState(false)
  const [username, updateUsername] =
    useState<AuthContextInterface['username']>(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (userDetails) {
      const { email, first_name, last_name } = userDetails
      tracker.login(email?.toLowerCase(), first_name, last_name)
      mixpanel.identify(email?.toLowerCase())
      mixpanel.track('login', { date: new Date().toISOString() })

      try {
        // @ts-ignore
        window.clarity('set', 'email', email?.toLowerCase())
      } catch (e) {
        // do nothing
      }
    }
  }, [userDetails])

  const signIn = useCallback(async (user: AmplifyUser) => {
    // const [currentUser, attributes] = await Promise.all([
    //   getCurrentUser(),
    //   fetchUserAttributes(),
    // ])
    try {
      const attributes = await fetchUserAttributes()

      const userWithAttributes = {
        ...user,
        attributes,
      }

      const email = attributes?.email?.toLowerCase()
      const name = attributes?.given_name + ' ' + attributes?.family_name
      mixpanel.identify(email)
      mixpanel.set({
        $email: email,
        $name: name,
        USER_ID: email,
        cognito_id: attributes?.sub,
      })

      // @ts-ignore
      window.clarity('set', 'email', email)

      setUser(userWithAttributes as User)
      setAuthenticated(true)
    } catch (e) {
      console.log(e)
    }

    try {
      const { tokens } = await fetchAuthSession()
      if (tokens?.idToken?.toString()) {
        setToken(tokens?.idToken?.toString())
      } else {
      }
    } catch (error) {
      console.error('Error fetching token:', error)
    }

    try {
      const currentUserDetailsResponse = await fetchUser()
      setUserDetails(currentUserDetailsResponse.data)
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(requestAccessURL)
      }
    }
  }, [])

  const signOut = useCallback(async () => {
    await amplifySignOut()
    setAuthenticated(false)
    setUser(null)
    axiosInstance.defaults.headers.common['Authorization'] = null
    mixpanel.reset()
    queryClient.clear()
    history.push(signinURL)
  }, [])

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true)
        // if user is already authenticated, construct their user obj
        const user = await getCurrentUser()

        signIn(user as AmplifyUser)
        updateUsername(null)
      } catch (e) {
        if ([trialSignupURL].includes(location.pathname)) {
          history.push(trialSignupURL)
        } else if (
          !hasTemporaryToken() &&
          ![setupPasswordURL, newPasswordURL].includes(location.pathname) &&
          ![setupPasswordURL, newPasswordURL, signinCallbackURL].includes(
            location.pathname,
          )
        ) {
          if (
            history?.location?.pathname &&
            history.location.pathname !== '/' &&
            history.location.pathname !== '/sign-in'
          ) {
            history.push(
              `${signinURL}?redirect=${location.pathname}${location.search}`,
            )
          } else {
            history.push(`${signinURL}`)
          }
        }
      } finally {
        setLoading(false)
      }
    }

    checkAuth()
  }, [])

  if (loading) {
    return (
      <LoaderWrapper>
        <LoadingContent>
          <Loader />
        </LoadingContent>
      </LoaderWrapper>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        username,
        updateUsername,
        userDetails,
        isAuthenticated: authenticated,
        signIn,
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`

const LoadingContent = styled.div`
  position: absolute;
  top: 200px;
`
