import React, { useMemo, useState } from 'react'
import { OutlierType } from '../../../../model'
import { Filters, ProjectState } from 'features/project/types'
import Markdown from 'marked-react'
import SemanticReviewCarousel from '../../../../../../../../components/SemanticReviewCarousel/SemanticReviewCarousel'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import InsightReviews from './InsightReviews/InsightReviews'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import YogiInfo from '../../../../../../../../components/UI/YogiInfo/YogiInfo'
import { darkBlueFontColor, lightBlueFontColor } from 'assets/styles/variables'
import useAutoInsights from '../../../../hooks/useAutoInsights'
import { useQuery } from 'react-query'
import { getFeedback } from 'features/project/features/feedback'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import styled from 'styled-components'

interface Props {
  outlier: OutlierType
  sectionRefs: React.MutableRefObject<{ [p: string]: HTMLDivElement | null }>
}

const bgColors = [
  // '#FFEFEC',
  '#FFFFFF',
  // '#FFFAF0',
  '#F2F2F2',
  '#F6FAFF',
  // '#F7F5FF',
  // '#F5FFFE',
  // '#F5FFF4',
]

export const InsightAnalysis: React.FC<Props> = ({ outlier, sectionRefs }) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [page, setPage] = useState<number>(1)

  // hooks
  const { data: feature_flags } = useFeatureFlags()

  // api
  const { analysis: parsedAnalysis } = outlier
  const { outlierFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchQuery: outlier.filter.search_terms,
    searchCondition: outlier.filter.search_criteria,
  })

  // const needed for api
  const order = 'desc'
  const orderField = 'create_time'

  // api
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading: postsLoading,
  } = useQuery(
    [
      `feedback-posts_`,
      projectId,
      outlierFilters.values,
      outlierFilters.searchQuery,
      outlierFilters.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size:
            // one project had a LOT of dupes - this was a hackaround
            projectId === 'ca805d9d-b814-47dd-bdbd-74b1737eb9d4' ? 120 : 24,
          // projectId === '5ecaef81-57d1-4d5d-935b-e7e7375d141e' ? 120 : 24,
        },
        body: {
          criteria: outlierFilters.values,
          search_terms: outlierFilters.searchQuery,
          search_criteria: outlierFilters.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  // Randomize the colors
  const colors = useMemo(
    () =>
      bgColors
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value),
    [],
  )

  return (
    <Container>
      <Body>
        <Content>
          <>
            {parsedAnalysis ? (
              <>
                {parsedAnalysis.body.map((section, index) => (
                  <Section
                    key={index}
                    ref={(el) =>
                      (sectionRefs.current[`analysis-${index}`] = el)
                    }
                  >
                    <Title>{section.title}</Title>
                    <Analysis
                      style={{
                        backgroundColor: colors[index % colors.length],
                      }}
                    >
                      <Markdown>{section.explanation}</Markdown>
                      {feature_flags?.semantic_search_proj ? (
                        <SemanticReviewCarousel
                          phrase={section.title}
                          brands={
                            outlier.context_with_value?.context === 'brand'
                              ? [outlier.context_with_value.value]
                              : []
                          }
                          products={
                            outlier.context_with_value?.context === 'product'
                              ? [outlier.context_with_value.value]
                              : []
                          }
                          themes={outlier.theme ? [outlier.theme] : []}
                          style={{ marginBottom: '20px' }}
                        />
                      ) : (
                        <YogiInfo
                          color={lightBlueFontColor}
                          style={{
                            marginBottom: 15,
                            fontSize: 18,
                            fontWeight: 500,
                            color: '#63B7D4',
                          }}
                        >
                          <div
                            style={{
                              color: '#63B7D4',
                            }}
                          >
                            "{section.evidence}"
                          </div>
                        </YogiInfo>
                      )}
                      <Markdown>{section.implication}</Markdown>
                    </Analysis>
                  </Section>
                ))}
              </>
            ) : (
              <LogoSpinnerSmall>
                <p>Loading Analysis...</p>
              </LogoSpinnerSmall>
            )}
          </>
          <Section
            key={-2}
            ref={(el) => (sectionRefs.current['reviews'] = el)}
            // style={{
            //   display: analysisBodyIndex === -2 ? 'block' : 'none',
            // }}
          >
            <Title>Reviews</Title>
            <ReviewSection>
              <InsightReviews
                outlier={outlier}
                filterValues={outlierFilters}
                postData={postData}
                page={page}
                setPage={setPage}
                postsLoading={postsLoading}
                postsFetching={postsFetching}
              />
            </ReviewSection>
          </Section>
        </Content>
      </Body>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 10px;
`

const Body = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
`

const Content = styled.div`
  padding-top: 0;
  flex-basis: 75%;
  flex-grow: 1;
  //max-width: 1000px;
  font-size: 16px;
`

const Section = styled.div`
  color: ${darkBlueFontColor};
  line-height: 30px;
  margin-bottom: 38px;
`

const Title = styled.div`
  margin-left: 35px;
  font-size: 22px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 20px;
`

const Analysis = styled.div<{ color?: string }>`
  padding: 20px 35px;
  background: ${({ color }) => color ?? 'white'};
  border-radius: 20px;
  box-shadow: 0 0 7px 0 #00000040;
`

const ReviewSection = styled.div<{ color?: string }>`
  padding: 25px;
  background: ${({ color }) => color ?? 'white'};
  border-radius: 20px;

  max-height: 600px;
  overflow: auto;
  background: #f6f4f4;
`
