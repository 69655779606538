import React from 'react'
import type { ValidationResult } from 'react-aria-components'
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
  Text,
} from 'react-aria-components'
import {
  getLocalTimeZone,
  today,
  fromDate,
  parseDate,
} from '@internationalized/date'

type MyDateRangePickerProps = {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  minDate: Date
  maxDate: Date
  value: [Date, Date]
  onChange: (dates: [Date, Date]) => void
  props?: any
}

export const NewCalendar: React.FC<MyDateRangePickerProps> = ({
  label,
  description,
  errorMessage,
  minDate,
  maxDate,
  value,
  onChange,
  props,
}) => {
  return (
    <DateRangePicker
      minValue={minDate ? fromDate(minDate, getLocalTimeZone()) : undefined}
      maxValue={today(getLocalTimeZone())}
      onChange={(value) => {
        if (value) {
          // if either input exceeds the valid date range, snap them to the ends
          if (value.start.compare(fromDate(minDate, getLocalTimeZone())) < 0) {
            value.start = fromDate(minDate, getLocalTimeZone())
          }
          if (value.end.compare(fromDate(maxDate, getLocalTimeZone())) > 0) {
            value.end = today(getLocalTimeZone())
          }
          onChange([
            value.start?.toDate(getLocalTimeZone()),
            value.end?.toDate(getLocalTimeZone()),
          ])
        }
      }}
      value={{
        start: parseDate(value[0].toISOString().split('T')[0]),
        end: parseDate(value[1].toISOString().split('T')[0]),
      }}
      {...props}
    >
      <Label>{label}</Label>
      <Group>
        <DateInput slot="start">
          {(segment) => <DateSegment segment={segment} />}
        </DateInput>
        <span aria-hidden="true">&nbsp;–&nbsp;</span>
        <DateInput slot="end">
          {(segment) => <DateSegment segment={segment} />}
        </DateInput>
        <Button>▼</Button>
      </Group>
      {description && <Text slot="description">{description}</Text>}
      <Popover>
        <Dialog>
          <RangeCalendar>
            <header>
              <Button slot="previous">◀</Button>
              <Heading />
              <Button slot="next">▶</Button>
            </header>
            <CalendarGrid>
              {(date) => <CalendarCell date={date} />}
            </CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </DateRangePicker>
  )
}
