import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { OutlierType } from '../../../../model'
import { getOutlierCharts, getOutlierTitle } from '../../../../utils'
import FavoriteInsightButton from '../../../FavoriteInsightButton/FavoriteInsightButton'
import { ProjectState } from 'features/project/types'
import mixpanel from '../../../../../../../trackers/mixpanel'
import {
  buttonBlue,
  chartCardColorHover,
  contextCardBackground,
  contextCardBackgroundHover,
  lightBlueFontColor,
  primaryOrange,
  selectableCardBackgroundNotSelected,
  selectableCardBackgroundSelected,
  selectableCardBorderNotSelected,
  selectableCardBorderSelected,
} from 'assets/styles/variables'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { getProductHierarchiesForProject } from 'features/project/features/products/model'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { FilterOptionSelect } from 'features/project/features/filters/types'
import { FocusListOption } from 'features/project/features/catalog/Components/CatalogList/CatalogList'
import {
  ArrowRightOutlined,
  BarcodeOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { CATALOG_URL } from 'features/project/utils'
import useAutoInsights from 'features/project/features/Insights/hooks/useAutoInsights'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import useCustomDashboards from 'features/project/hooks/useCustomDashboards'
import { AuthContext } from 'features/auth'

interface Props {
  outlier: OutlierType
  isSelected: boolean
}

const mapContextToCategory = (context: string | undefined) => {
  switch (context) {
    case 'product':
      return 'product_hierarchy'
    case 'source_type':
      return 'source'
    default:
      return context ?? 'product_hierarchy'
  }
}

const InsightListItem: React.FC<Props> = ({ outlier, isSelected }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const history = useHistory()

  const handleSelect = () => {
    const urlParams = new URLSearchParams(window.location.search)

    urlParams.set('id', outlier.uuid)
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
    mixpanel.track('insights feed', {
      action: 'select insight',
      value: getOutlierTitle(outlier),
    })
  }

  const { data: hierarchyData } = useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const context = outlier.context_with_value?.context

  const data: FocusListOption[] = useMemo(() => {
    if (context === 'product') {
      return (
        hierarchyData?.map(
          (el) =>
            ({
              value: el.hierarchy,
              n_posts: el.review_count,
              image_url: el.image_url,
              product_count: el.product_count,
            }) as any,
        ) ?? []
      )
    } else {
      return (
        (
          defaultFilterList.find(
            (el) => el.field === context,
          ) as FilterOptionSelect
        )?.values ?? []
      )
    }
  }, [defaultFilterList, context, hierarchyData /*sourceData*/])

  const item = data.find((el) => el.value === outlier.context_with_value?.value)
  const { outlierFilters, themeFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchCondition: outlier.filter.search_criteria,
    searchQuery: outlier.filter.search_terms,
  })

  const charts = useMemo(
    () => getOutlierCharts(outlier, outlierFilters, themeFilters),
    [outlier],
  )

  const chartItem = charts[0].chartItem

  const { filterValues } = chartItem

  // const { data: linkData } = useQuery(
  //   ['getFilterHash', projectId, filterValues, dashboardControls],
  //   () =>
  //     getBookmarkUrlPost(projectId, {
  //       page: `${CHARTS_URL}`,
  //       filterString: JSON.stringify({
  //         filters: [filterValues],
  //         pageControls: [dashboardControls],
  //       }),
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     cacheTime: Infinity,
  //     staleTime: Infinity,
  //     enabled: !!projectId && !!chartItem?.dashboardControls,
  //   },
  // )
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const { data: customDashboardsResponse, refetch: refetchCustomDashboards } =
    useCustomDashboards()

  const [isFavorite, setIsFavorite] = useState(false)

  const identifyMatchingDashboard = useCallback(() => {
    return customDashboardsResponse?.find(
      (customDashboard) =>
        customDashboard?.dashboard.dashboard_settings.extra_settings?.outlier
          ?.uuid === outlier.uuid,
    )
  }, [customDashboardsResponse, outlier])

  useEffect(() => {
    const match = identifyMatchingDashboard()
    setIsFavorite(
      !!match?.dashboard.dashboard_settings?.extra_settings?.active_users?.includes(
        currentUserEmail,
      ),
    )
  }, [customDashboardsResponse, identifyMatchingDashboard])

  return (
    <Container isSelected={isSelected} onClick={handleSelect}>
      <div>
        <Header isSelected={isSelected}>
          <Title>{getOutlierTitle(outlier)}</Title>
          <div
            style={{ minWidth: 25 }}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorite((prev) => !prev)
            }}
          >
            <Bookmark isFavorite={isFavorite}>
              <FavoriteInsightButton
                key={outlier.uuid}
                outlier={outlier}
                filterValues={filterValues}
              />
            </Bookmark>
          </div>
        </Header>
        {/*<YogiDivider style={{ margin: '5px 0px' }}></YogiDivider>*/}
        {/*<Tags isSelected={isSelected}>*/}
        {/*  {outlier.dateRanges*/}
        {/*    ?.sort()*/}
        {/*    ?.reverse()*/}
        {/*    ?.map((dateRange, index) => (*/}
        {/*      <Tooltip*/}
        {/*        title={`Outlier appeared in the trailing ${_.startCase(dateRange)}`}*/}
        {/*      >*/}
        {/*        <Tag key={index} color={'#FFE7E2'} style={{ color: mainText }}>*/}
        {/*          {_.startCase(dateRange)}*/}
        {/*        </Tag>*/}
        {/*      </Tooltip>*/}
        {/*    ))}*/}
        {/*</Tags>*/}
      </div>
      <Actions>
        <ActionItem>
          <Link
            style={{ textDecoration: 'none' }}
            to={`${CATALOG_URL}?category=${mapContextToCategory(context)}&item=${item?.value}`}
            target={'_blank'}
            onClick={(e) => {
              mixpanel.track('insights feed', {
                action: 'catalog link',
                value: item?.value,
              })
              e.stopPropagation()
            }}
          >
            {/*{productHierarchy && (*/}
            {/*  <CatalogListItem*/}
            {/*    item={*/}
            {/*      {*/}
            {/*        value: productHierarchy?.hierarchy,*/}
            {/*        n_posts: productHierarchy?.review_count,*/}
            {/*        image_url: productHierarchy?.image_url,*/}
            {/*        product_count: productHierarchy?.product_count,*/}
            {/*      } as any*/}
            {/*    }*/}
            {/*    category={'product_hierarchy'}*/}
            {/*    title={productHierarchy.hierarchy}*/}
            {/*    reviewCount={productHierarchy.review_count}*/}
            {/*    onSelect={() => {}}*/}
            {/*    selectedItem={''}*/}
            {/*    identifiers={[]}*/}
            {/*  ></CatalogListItem>*/}
            {/*)}*/}
            <ContextItem>
              <ContextInfo>
                <ContextTitle>{item?.value}</ContextTitle>
                <div>
                  {item?.product_count && (
                    <div style={{ marginBottom: 3 }}>
                      Items: {item?.product_count}
                    </div>
                  )}
                  <div>Ratings & Reviews: {item?.n_posts}</div>
                </div>
              </ContextInfo>

              {item?.image_url && (
                <ImageWrapper>
                  {item.image_url ? (
                    <img
                      src={item.image_url}
                      alt=""
                      style={{ height: 'auto', width: 'auto', maxHeight: 80 }}
                    />
                  ) : (
                    <BarcodeOutlined style={{ fontSize: '60px' }} />
                  )}
                </ImageWrapper>
              )}
            </ContextItem>
          </Link>
        </ActionItem>
        <Arrow>
          <RightOutlined style={{ fontSize: 40, marginBottom: 10 }} />
        </Arrow>
        {/*<ActionItem>*/}
        {/*  <Link*/}
        {/*    style={{ textDecoration: 'none' }}*/}
        {/*    // block linkout if link hash hasn't returned yet*/}
        {/*    target={linkData ? '' : '_blank'}*/}
        {/*    to={linkData ? `${CHARTS_URL}?filters=${linkData}` : '#'}*/}
        {/*    onClick={(e) => {*/}
        {/*      mixpanel.track('insights feed', {*/}
        {/*        action: 'open chart link',*/}
        {/*        value: getOutlierTitle(outlier),*/}
        {/*        chartId: chartItem.dashboardControls.chartId,*/}
        {/*      })*/}
        {/*      e.stopPropagation()*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ChartLinkItem>*/}
        {/*      {outlier.type === TIMESERIES_TYPE_NAME ? (*/}
        {/*        <>*/}
        {/*          <div>*/}
        {/*            <div>Sentiment</div>*/}
        {/*            <div>& Volume</div>*/}
        {/*            <div>Over Time</div>*/}
        {/*          </div>*/}
        {/*          <div style={{ marginTop: 18 }}>*/}
        {/*            <TimeseriesChartLinkSVG />*/}
        {/*          </div>*/}
        {/*        </>*/}
        {/*      ) : (*/}
        {/*        <>*/}
        {/*          <div>*/}
        {/*            <div>*/}
        {/*              {_.startCase(outlier.context_with_value?.context)}*/}
        {/*            </div>*/}
        {/*            <div>Sentiment</div>*/}
        {/*            <div>Ranking</div>*/}
        {/*          </div>*/}
        {/*          <ChartLinkSVG />*/}
        {/*        </>*/}
        {/*      )}*/}
        {/*    </ChartLinkItem>*/}
        {/*  </Link>*/}
        {/*</ActionItem>*/}
      </Actions>
      {/*<Info isSelected={isSelected}>*/}
      {/*  {outlier.type === TIMESERIES_TYPE_NAME ? (*/}
      {/*    <>*/}
      {/*      <InfoItem style={{ flexBasis: '100%' }}>*/}
      {/*        {!!outlier.date && new Date(outlier.date).toDateString()} -{' '}*/}
      {/*        {outlier.aggregation && (*/}
      {/*          <>*/}
      {/*            {moment(outlier.date)*/}
      {/*              // @ts-ignore TODO : 'half' is an option, but moment doesn't accept it*/}
      {/*              .add(1, outlier.aggregation)*/}
      {/*              .toDate()*/}
      {/*              .toDateString()}*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*      </InfoItem>*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <>*/}
      {/*      <Tag*/}
      {/*        color={'#E0E5F2'}*/}
      {/*        style={{ color: mainText, whiteSpace: 'wrap' }}*/}
      {/*      >*/}
      {/*        {_.startCase(outlier.context_with_value?.context)}*/}
      {/*        {': '}*/}
      {/*        {outlier.context_with_value?.value}*/}
      {/*      </Tag>*/}
      {/*      <Tag*/}
      {/*        color={'#E0E5F2'}*/}
      {/*        style={{ color: mainText, whiteSpace: 'wrap' }}*/}
      {/*      >*/}
      {/*        {outlier.theme ? getNormalizedThemeName(outlier.theme) : ''}*/}
      {/*      </Tag>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Info>*/}
    </Container>
  )
}

export default InsightListItem

const Actions = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
`

const ActionItem = styled.div`
  flex-basis: calc(50% - 15px);
  //flex-grow: 1;
`

const ContextItem = styled.div`
  height: 100%;
  width: 100%;
  background: ${contextCardBackground};
  border-radius: 10px;
  color: ${buttonBlue};
  font-size: var(--font-size-sm);
  padding: 25px 19px;

  display: flex;
  justify-content: space-between;
  gap: 10px;

  &:hover {
    background: ${contextCardBackgroundHover};
  }
`

const ContextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ContextTitle = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 600;
`

const ChartLinkItem = styled.div`
  height: 100%;
  width: 100%;
  color: ${primaryOrange};
  font-size: 17px;
  font-weight: 600;
  padding: 15px 19px 32px;

  display: flex;
  justify-content: space-between;

  &:hover {
    color: ${chartCardColorHover};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  //margin-right: 10px;
  max-width: 50%;
  background: white;
  border-radius: 5px;
  overflow: hidden;
`

const Arrow = styled.div`
  width: 45%;
  flex-grow: 1;
  display: flex;
  justify-content: end;
  align-items: end;
  color: transparent;

  transition: all 0.15s ease-in-out;
`

const Bookmark = styled.div<{ isFavorite: boolean }>`
  opacity: ${({ isFavorite }) => (isFavorite ? '1' : '0')};
  transition: all 0.15s ease-in-out;
`

const Container = styled.div<{ isSelected: boolean }>`
  flex-basis: calc(50% - 8px);
  //flex-grow: 1;
  padding: 20px;
  background-color: ${selectableCardBackgroundNotSelected};
  border: 2px solid ${selectableCardBorderNotSelected};
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 340px;
  box-shadow: 0 4px 10px 0 #00000026;

  &:hover {
    border-color: ${selectableCardBorderSelected};
    background-color: ${selectableCardBackgroundSelected};
    ${Arrow} {
      color: ${lightBlueFontColor};
    }
    ${Bookmark} {
      opacity: 100%;
    }
  }

  transition: all 0.15s ease-in-out;
`

const Header = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: ${({ isSelected }) =>
    isSelected ? lightBlueFontColor : lightBlueFontColor};
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  //line-height: 1.75;
  line-height: 30px;
  flex-grow: 2;
`
